import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import { Box } from "@material-ui/core";
import { CustomCircularProgress } from "../../../components/Progress/CustomCircularProgress";
import DehazeIcon from '@mui/icons-material/Dehaze';
import Popup from "../../../components/Controls/Popup";
import Notification from "../../../components/SnackBar/Notification";
import RMInventoryForm from "./RMInventoryForm";

import { makeStyles, TableCell, Tooltip } from "@material-ui/core";
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme from "../../../components/CustomTheme/CustomTheme";
import Confirm from "./Confirm";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  iconButtonColor: {
    color: "#fff",
    "&:hover": {
      color: "#0000ff",
    },
  },
}));

function RMInventory() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [fabricRecord, setFabricRecord] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [rentMachineInventoryList, setRentMachineInventoryList] = useState([]);
  const user_type = localStorage.getItem("user_type");
  const [openRentMachineDetailsPopup, setOpenRentMachineDetailsPopup] = useState(false);


  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const axiosHeader = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  async function fetchRentMachineInventoryList() {
    setIsLoading(true);
    try {
      await axios
        .get(`/api/rent-machine-inventory-list/`, axiosHeader)
        .then((res) => {
          setRentMachineInventoryList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchStyleWiseMachineDetail(id) {
    setIsLoading(true);
    try {
      await axios
        .get(`/api/rent-machine-inventory-details/${id}/`, axiosHeader)
        .then((res) => {
          setRecordForEdit(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const postRentMachineSupplier = async (values, setSubmitting) => {
    try {
      await axios
        .post("/api/rent-machine-inventory-create/", values, axiosHeader)
        .then((resp) => {
          setFabricRecord(resp.data);
          // alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateRentMachineSupplier = async (values, setSubmitting) => {
    try {
      await axios
        .put(
          `/api/rent-machine-inventory-update/${values.id}/`,
          values,
          axiosHeader
        )
        .then((resp) => {
          setFabricRecord(resp.data);
          // alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRMSupplier = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-machine-inventory-delete/${values.id}/`, axiosHeader
        )
        .then((resp) => {
          fetchRentMachineInventoryList();
          setDeletedItem(null);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    fetchRentMachineInventoryList();
  }, [fabricRecord, searchOpen]);

  const openInPopup = (item) => {
    fetchStyleWiseMachineDetail(item.id);
    // setRecordForEdit(item);
    setOpenPopup(true);
  };
  const openInDetailsPopup = (item) => {
    fetchStyleWiseMachineDetail(item?.id)
    setOpenRentMachineDetailsPopup(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "unit",
      label: "Unit",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "requisition",
      label: "Requisition",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "supplier",
      label: "Supplier",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "chalan_no",
      label: "Challan no",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let item;
          tableMeta.tableData.forEach(function (rent) {
            if (rent.id == tableMeta.rowData[0]) return (item = rent);
          });
          return (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  if (user_type === "Admin" || "Maintenance Coordinate") {
                    openInPopup(item);
                  } else {
                    alert("You have no update permission");
                  }
                  // openInPopup(item);
                }}
              >
                <EditIcon fontSize="medium" />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (user_type === "Admin" || user_type === "Maintenance Coordinate") {
                    // deleteRMSupplier(item);
                    setConfirmDelete(true);
                    handleDelete(item);
                  } else {
                    alert("You have no delete permission");
                  }
                }}
              >
                <DeleteIcon color="error" fontSize="medium" />
              </IconButton>
              {/* <Link
                to={{
                  pathname: "/app/machine/details",
                  state: item.id,
                }}
              >
                <IconButton color="primary">
                  <ArrowRightAltIcon fontSize="medium" />
                </IconButton>
              </Link> */}
              <IconButton
                color="primary"
                onClick={() => {
                  openInDetailsPopup(item);
                }}
              >
                <DehazeIcon fontSize="medium" style={{'color': 'purple'}} />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const detailColumns = [
    {
      name: "machine_type_name",
      label: "Machine Type",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "rent_value",
      label: "Rent Value",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ]

  const handleDelete = (item) => {
    setDeletedItem(item);
    setConfirmDelete(true);
  };

  const confirmDeleteRMS = () => {
    if (deletedItem) {
      deleteRMSupplier(deletedItem);
    }
    setConfirmDelete(!confirmDelete);
  };

  const addOrEdit = (rms, resetForm, setSubmitting) => {
    if (!rms.id) postRentMachineSupplier(rms, setSubmitting);
    else updateRentMachineSupplier(rms, setSubmitting);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
  };

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    filterType: "select",
    selectableRows: 'none', //multiple | single | none 
    responsive: "standard",
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100, 200],
    customToolbar: () => {
      return (
        <>
          <Tooltip title={"Add New"}>
            <IconButton
              className={classes.iconButtonColor}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    print: false,
    download: false,
    searchText: searchValue,
    searchOpen: searchOpen,
  };

  const detailOptions = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    // rowsPerPage: [],
    // rowsPerPageOptions: [],
    filter: false,
    selectableRows: 'none', //multiple | single | none
    print: false,
    download: false,
  }

  return (
    <React.Fragment>
      <Box position="relative">
        <ThemeProvider theme={CustomTheme}>
          <div>
            <BreadCrumb routeSegments={[{ name: "Rent Machine Inventories" }]} />
            {isLoading ? (
              <MUIDataTable
                title={"Rent Machine Inventories List"}
                columns={columns}
                options={options}
                className={classes.pageContent}
              />
            ) : (
              <MUIDataTable
                title={"Rent Machine Inventories List"}
                data={rentMachineInventoryList}
                columns={columns}
                options={options}
                className={classes.pageContent}
              />
            )}
            <TableCell className={classes.MuiTableCell} />
            <Popup
              title="Rent Machine Inventories Form"
              width="xl"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <RMInventoryForm
                recordForEdit={recordForEdit}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                addOrEdit={addOrEdit}
                axiosHeader={axiosHeader}
                rentMachineInventoryList={rentMachineInventoryList}
              />
            </Popup>
            <Confirm
              open={confirmDelete}
              setConfirmDelete={setConfirmDelete}
              onClose={() => setConfirmDelete(false)}
              actionText="delete"
              onConfirm={confirmDeleteRMS}
            />

            <Popup
              title="Rent Machine Details"
              width="xl"
              openPopup={openRentMachineDetailsPopup}
              setOpenPopup={setOpenRentMachineDetailsPopup}
            >
              <MUIDataTable
                // title={"Rent Machine Details"}
                data={recordForEdit?.rmd}
                columns={detailColumns}
                options={detailOptions}
                className={classes.pageContent}
              />
            </Popup>
            <Notification notify={notify} setNotify={setNotify} />
          </div>
        </ThemeProvider>
      </Box>
    </React.Fragment>
  );
}

export default RMInventory;