import React from "react";
import { TextField } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const Input = (props) => {
  const { name, value, label, onChange, error, helperText, ...other } = props;
  return (
      <TextField
        variant="outlined"
        label={label}
        onChange={onChange}
        name={name}
        value={value}
        error={error}
        helperText={helperText}
        {...other}
      />
  );
};

export const InputCheckBox = (props) => {
  const { label, name, checked, onChange, ...other } = props;
  return (
      <FormControlLabel
      control={
        <Checkbox
          label={label}
          name={name}
          checked={checked}
          onChange={onChange}
          color="primary"
          {...other}
        />
      }
      label={label}
    />
  );
};
