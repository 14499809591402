import {
  Box,
  CircularProgress,
  Grid,
  Button,
  IconButton,
  MuiThemeProvider,
  TableCell,
  TextField,
  Tooltip,
  createTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form } from "../../../components/Form/useForm";
import { useFormik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { CustomCircularProgress } from "../../../components/Progress/CustomCircularProgress";
import Controls from "../../../components/Controls/Controls";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import Confirm from "./Confirm";
import Notification from "../../../components/SnackBar/Notification";

const initialFValues = {
  billing_month: new Date(),
  bill_submission_month: new Date(),
  supplier: "",
  supplier_bill_no: "",
  rmb: [
    {
      line: "",
      rmbl: [
        {
          machine_type: "",
          serial_no: "",
          chalan_no: "",
          in_date: "",
          running_month_date: "",
          actual_running_days: "",
          per_day_cost: "",
          total_amount: "",
          id_for_is_machine_return: "",
          ids_for_is_billed: []
        }
      ]
    },
  ],
};

const style = makeStyles({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const useButtonStyle = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    borderRadius: '25px',
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
    },
    '&:active': {
      transform: 'translateY(2px)',
    },
  },
  primaryButton: {
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
}));

const RMBillingForm = (props) => {
  const { addOrEdit, recordForEdit, AxiosHeader, isLoading, setIsLoading } = props;

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          root: {
            backgroundColor: "#50d07d",
            color: "#fff",
          },
          icon: {
            color: "#fff",
            "&:hover": {
              color: "#0000ff",
            },
          },
        },
        MuiTableCell: {
          root: {
            padding: "2px",
            "&:last-child": {
              border: 0,
            },
          },
        },
        MuiTableRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#89898940",
              color: "#fff",
            },
          },
        },
      },

      typography: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
    });
  const [supplierList, setSupplierList] = useState([]);
  const [confirmDeleteRMD, setConfirmDeleteRMD] = useState(false);
  const [deletedRMDData, setDeletedRMDData] = useState(null);
  const [confirmDeleteSWl, setConfirmDeleteSWL] = useState(false);
  const [confirmDeleteSwlData, setConfirmDeleteSWlData] = useState(null);
  const [supplierBillingLoading, setSupplierBillingLoading] = useState(false);
  const [machineTypeList, setMachineTypeList] = useState([]);
  const [productionLine, setProductionLine] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validationSchema = Yup.object().shape({
    billing_month: Yup.string().required("Billing month is required"),
    // bill_submission_month: Yup.string().required("Bill submission month is required"),
    rmb: Yup.array().of(
      Yup.object().shape({
        supplier: Yup.number().required("Supplier is required"),
        supplier_bill_no: Yup.string().required("Supplier bill nois required"),
        system_billing: Yup.number().required("Billing cost is required"),
        custom_billing: Yup.number().required("Custom billing cost is required"),
      })
    ),
  });

  const classes = style();
  const btnClasses = useButtonStyle();

  const formik = useFormik({
    initialValues: initialFValues,
    // validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(false);
      addOrEdit(values, resetForm, setSubmitting);
    },
  });

  useEffect(() => {
    if (recordForEdit != null)
      formik.setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const sumTotalCost = (values) => {
    let totalCost = 0;
  
    // Iterate over each element in rmb array
    values.rmb.forEach((item) => {
      // Iterate over each element in rmbl array
      item.rmbl.forEach((subItem) => {
        // Add total_cost to totalCost variable
        totalCost += parseFloat(subItem.actual_running_days * subItem.per_day_cost) || 0;
      });
    });
  
    return totalCost;
  };

  const totalCost = sumTotalCost(formik.values);

  useEffect(() => {
    // Set loading state to true
    setIsLoading(true);

    // supplier list
    async function getSupplierList() {
      const response = await fetch("/api/rent-supplier-list/", AxiosHeader);
      const body = await response.json();
      setSupplierList(body);
    }

    async function getMachineTypeList() {
      const response = await fetch("/api/machine/type/list/", AxiosHeader);
      const body = await response.json();
      setMachineTypeList(body);
    }

    // production line list
    async function getProductionLine() {
      const response = await fetch("/api/production/line/list/", AxiosHeader);
      const body = await response.json();
      setProductionLine(body);
    }

    // Call all data fetching functions
    Promise.all([
      getSupplierList(),
      getMachineTypeList(),
      getProductionLine(),
    ])
      .then(() => {
        // Set loading state to false once all data fetching is done
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  const handleRequisitionCall = async () => {
    setSupplierBillingLoading(true);
    try {
      const response = await fetch(`/api/rent-billing-supplier-wise/${!recordForEdit && formik.values.supplier}/`, {
        method: 'POST',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({'date': formik.values.billing_month})
      });
      const body = await response.json();

      if (body != null){
        const transformedData = body.map(item => ({
          line: item.line,
          rmbl: item.machine_details.map(machine => ({
            machine_type: machine.machine_type,
            serial_no: machine.serial_no,
            chalan_no: machine.chalan_no,
            in_date: machine.in_date,
            running_month_date: machine.running_month_date,
            actual_running_days: machine.actual_running_days,
            per_day_cost: machine.per_day_cost,
            total_amount: machine.total_amount,
            machine_reference: machine.machine_reference,
            ids_for_is_billed: machine.ids_for_is_billed
          })),
        }));

        // Set the transformed data to the form's rmi field
        formik.setValues(prevValues => ({
          ...prevValues,
          rmb: transformedData,
        }))
      }
      // setrequisitionData(body);
      setSupplierBillingLoading(false);
    } catch (error) {
      console.error('Error calling the API:', error);
      setSupplierBillingLoading(false);
    }
  };


  const handleLineChange = (value, rmbIndex) => {
    // Update the form values with the new "line" for the specified "rmb" item
    formik.setFieldValue(`rmb[${rmbIndex}].line`, value);
  };

  const handleSupplierBillNo = (value, rmbIndex) => {
    // Update the form values with the new "supplier_bill_no" for the specified "rmb" item
    formik.setFieldValue(`rmb[${rmbIndex}].supplier_bill_no`, value);
  };

  const handleCustomBilling = (value, rmbIndex) => {
    // Update the form values with the new "custom_billing" for the specified "rmb" item
    formik.setFieldValue(`rmb[${rmbIndex}].custom_billing`, value);
  };

  const handleRemark = (value, rmbIndex) => {
    // Update the form values with the new "remark" for the specified "rmb" item
    formik.setFieldValue(`rmb[${rmbIndex}].remark`, value);
  };

  const updateAcutalRunningDays = (rmbIndex, rmblIndex, newActualRunningDays) => {
    const updatedRMB = [...formik.values.rmb];
    const updatedRMBL = [...updatedRMB[rmbIndex].rmbl];
    updatedRMBL[rmblIndex].actual_running_days = newActualRunningDays;
    updatedRMB[rmbIndex].rmbl = updatedRMBL;
    formik.setFieldValue(`rmb`, updatedRMB);
  };

  const updatePerDayCost = (rmbIndex, rmblIndex, newPerDayCost) => {
    const updatedRMB = [...formik.values.rmb];
    const updatedRMBL = [...updatedRMB[rmbIndex].rmbl];
    updatedRMBL[rmblIndex].per_day_cost = newPerDayCost;
    updatedRMB[rmbIndex].rmbl = updatedRMBL;
    formik.setFieldValue(`rmb`, updatedRMB);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "line",
      label: "Line",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rmbIndex = tableMeta.rowIndex;
          return (
            <>
              <Autocomplete
                id={`rmb[${rmbIndex}].line`}
                options={productionLine}
                getOptionLabel={(option) => option.name || ""}
                getOptionSelected={(option, value) => option.id === value}
                value={
                  productionLine.find((option) => option.id === value) || null
                }
                onChange={(value, newValue) => {
                  handleLineChange(newValue ? newValue.id : null, rmbIndex);
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Line"
                    variant="outlined"
                    error={
                      formik.touched[`rmb[${rmbIndex}].line`] &&
                      Boolean(formik.errors[`rmb[${rmbIndex}].line`])
                    }
                    helperText={
                      formik.touched[`rmb[${rmbIndex}].line`] &&
                      formik.errors[`rmb[${rmbIndex}].line`]
                    }
                    style={{ width: "250px" }}
                  />
                )}
                disabled
              />
            </>
          );
        },
      },
    },
    {
      name: "rmbl",
      label: "Line wise billing details",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value?.map((rmblItem, rmblIndex) => {
                const isLastItem = rmblIndex === value.length - 1;
                const isOddIndex = rmblIndex % 2 === 1;
                const gridContainerClassName = isOddIndex
                  ? "gray-background"
                  : "";
                return (
                  <div key={rmblIndex}>
                    {/* Check if "rmbl" and its elements exist before accessing them */}
                    {rmblItem && (
                      <>
                        <Grid
                          container
                          spacing={1}
                          sx={{ m: 2 }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={2}>
                            <Autocomplete
                              id={`rmbl[${rmblIndex}].machine_type`}
                              options={machineTypeList}
                              getOptionLabel={(option) => option.name || ""}
                              getOptionSelected={(option, value) =>
                                option.id === value
                              }
                              value={
                                machineTypeList.find(
                                  (option) => option.id === rmblItem.machine_type
                                ) || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  // style={{ width: "358px" }}
                                  {...params}
                                  label="Machine Type"
                                  variant="outlined"
                                />
                              )}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Controls.Input
                              label="Serial No"
                              name={`rmbl[${rmblIndex}].serial_no`}
                              value={rmblItem?.serial_no || ""}
                              type='text'
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Controls.Input
                              label="Chalan No"
                              name={`rmbl[${rmblIndex}].chalan_no`}
                              value={rmblItem?.chalan_no || ""}
                              type='text'
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Controls.Input
                              label="Machine In Date"
                              name={`rmbl[${rmblIndex}].in_date`}
                              value={rmblItem?.in_date || ""}
                              type='text'
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Controls.Input
                              label="Running Month Date Range"
                              name={`rmbl[${rmblIndex}].running_month_date`}
                              value={rmblItem?.running_month_date || ""}
                              type='text'
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Controls.Input
                              label="Actual Running Days"
                              name={`rmbl[${rmblIndex}].actual_running_days`}
                              value={rmblItem?.actual_running_days || ""}
                              type='number'
                              onChange={(e) => {
                                updateAcutalRunningDays(
                                  tableMeta?.rowIndex,
                                  rmblIndex,
                                  Number(e.target.value)
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Controls.Input
                              label="Per Day Cost"
                              name={`rmbl[${rmblIndex}].per_day_cost`}
                              value={rmblItem?.per_day_cost || ""}
                              type="number"
                              onChange={(e) => {
                                updatePerDayCost(
                                  tableMeta?.rowIndex,
                                  rmblIndex,
                                  Number(e.target.value)
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Controls.Input
                              label="Total Amount"
                              name={`rmbl[${rmblIndex}].total_amount`}
                              value={rmblItem?.actual_running_days * rmblItem?.per_day_cost || ""}
                              type="number"
                              InputProps={{
                                style: {fontWeight: 'bold', fontSize: '18px'},
                              }}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          );
        },
      },
    },
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    filterType: "select",
    selectableRows: 'none',
    responsive: "standard",
    rowsPerPage: [20],
    rowsPerPageOptions: [],
    print: false,
    download: false,
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <React.Fragment>
        <Grid container alignItems="flex-start" spacing={1}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <Box style={{ width: "100%" }}>
              <Grid container alignItems="flex-start" spacing={1}>
                <Grid item md={3} sm={4} xs={6}>
                  <Controls.MonthPicker
                    label="Billing Month"
                    name="billing_month"
                    value={formik.values.billing_month}
                    onChange={value => {
                      formik.setFieldValue("billing_month", value)
                    }}
                    onBlur={formik.handleBlur}
                    disabled={recordForEdit ? true : false}
                    fullWidth
                  />
                </Grid>
                {/* <Grid item md={2} sm={4} xs={6}>
                  <Controls.MonthPicker
                    label="Bill Submission Month"
                    name="bill_submission_month"
                    value={formik.values.bill_submission_month}
                    onChange={value => {
                      formik.setFieldValue("bill_submission_month", value)
                    }}
                    onBlur={formik.handleBlur}
                    disabled={recordForEdit ? true : false}
                    fullWidth
                  />
                </Grid> */}

                <Grid item md={3} sm={4} xs={6}>
                  <Autocomplete
                    id="supplier"
                    options={supplierList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Supplier"
                        variant="outlined"
                        error={
                          formik.touched.supplier && Boolean(formik.errors.supplier)
                        }
                        helperText={formik.touched.supplier && formik.errors.supplier}
                      />
                    )}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      supplierList.find(
                        (supplier) => supplier.id === formik.values.supplier
                      ) ||
                      formik.values.supplier ||
                      null
                    }
                    onChange={(_event, supplier) => {
                      formik.setFieldValue("supplier", supplier ? supplier.id : null);
                    }}
                    disabled={recordForEdit ? true : false}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.Input
                    label="Supplier Bill No"
                    name="supplier_bill_no"
                    value={formik.values.supplier_bill_no}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type='text'
                    error={formik.touched.supplier_bill_no && Boolean(formik.errors.supplier_bill_no)}
                    helperText={formik.touched.supplier_bill_no && formik.errors.supplier_bill_no}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRequisitionCall}
                    className={`${btnClasses.button} ${btnClasses.primaryButton}`}
                    disabled={supplierBillingLoading || recordForEdit}
                  >
                    {supplierBillingLoading ? <CircularProgress size={24} /> : 'Load Bill'}
                  </Button>
                </Grid>

                <Grid md={12} sm={12} xs={12} item style={{ marginTop: 10 }}>
                  {/* Table */}
                  {isLoading ? (
                    <MUIDataTable
                      title={"Billing Details"}
                      columns={columns}
                      options={options}
                      className={classes.pageContent}
                    />
                  ) : (
                    <MUIDataTable
                      title={"Billing Details"}
                      data={formik.values.rmb}
                      columns={columns}
                      options={options}
                      className={classes.pageContent}
                    />
                  )}

                  <TableCell className={classes.MuiTableCell} />
                </Grid>

                <Grid item md={9} sm={6} xs={9}></Grid>
                <Grid item md={3} sm={6} xs={3}>
                  <div style={{marginLeft: '20px', textAlign: 'left'}}>
                    <span style={{fontSize: '25px'}}>Total amount: </span>
                    <span style={{fontSize: '30px', fontWeight: 'bold', color: 'purple'}}>{totalCost} ৳</span>
                  </div>
                </Grid>

                <Grid md={12} sm={4} xs={6} item style={{ marginTop: 10 }}>
                  <div className={classes.wrapper}>
                    <Controls.Button
                      buyer="submit"
                      disabled={formik.isSubmitting}
                      type="submit"
                      text="Submit"
                    />
                    {formik.isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    <Controls.Button
                      text="Reset"
                      color="default"
                      onClick={formik.resetForm}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Notification notify={notify} setNotify={setNotify} />
          </MuiThemeProvider>
        </Grid>
      </React.Fragment>
    </Form>
  );
};

export default RMBillingForm;