import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MuiThemeProvider,
  TableCell,
  TextField,
  Tooltip,
  createTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form } from "../../../components/Form/useForm";
import { useFormik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { CustomCircularProgress } from "../../../components/Progress/CustomCircularProgress";
import Controls from "../../../components/Controls/Controls";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import Confirm from "./Confirm";
import Notification from "../../../components/SnackBar/Notification";

const initialFValues = {
  name: "",
  contact_person: "",
  contact_number: "",
  email: "",
  address: "",
  rmd: [
    {
      machine_type: "",
      rent_value: 1,
    },
  ],
};

const style = makeStyles({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const RMSupplierForm = (props) => {
  const { addOrEdit, recordForEdit, AxiosHeader, isLoading, setIsLoading } = props;

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          root: {
            backgroundColor: "#50d07d",
            color: "#fff",
          },
          icon: {
            color: "#fff",
            "&:hover": {
              color: "#0000ff",
            },
          },
        },
        MuiTableCell: {
          root: {
            padding: "2px",
            "&:last-child": {
              border: 0,
            },
          },
        },
        MuiTableRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#89898940",
              color: "#fff",
            },
          },
        },
      },

      typography: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
    });
  const [machineTypeList, setMachineTypeList] = useState([]);
  const [confirmDeleteRMD, setConfirmDeleteRMD] = useState(false);
  const [deletedRMDData, setDeletedRMDData] = useState(null);
  const [confirmDeleteSWl, setConfirmDeleteSWL] = useState(false);
  const [confirmDeleteSwlData, setConfirmDeleteSWlData] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_person: Yup.string().required("Contact person is required"),
    contact_number: Yup.string().required("Contact Number is required"),
    email: Yup.string().required("Email is required"),
    address: Yup.string().required("Address is required"),
    rmd: Yup.array().of(
      Yup.object().shape({
        machine_type: Yup.number().required("Machine Type is required"),
      })
    ),
  });

  const classes = style();

  const formik = useFormik({
    initialValues: initialFValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(values)
      setSubmitting(false);
      addOrEdit(values, resetForm, setSubmitting);
    },
  });

  useEffect(() => {
    if (recordForEdit != null)
      formik.setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  useEffect(() => {
    // Set loading state to true
    setIsLoading(true);

    // type list
    async function getMachineTypeList() {
      const response = await fetch("/api/machine/type/list/", AxiosHeader);
      const body = await response.json();
      setMachineTypeList(body);
    }

    // Call all data fetching functions
    Promise.all([
      getMachineTypeList(),
    ])
      .then(() => {
        // Set loading state to false once all data fetching is done
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error fetching data:", error);
        // Set loading state to false to stop loading indicator in case of error
        setIsLoading(false);
      });
  }, []);

  const deleteRentMachineDetails = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-supplier-machine-detail-delete/${values.id}/`, AxiosHeader
        )
        .then((resp) => {
          // ToDO: adjust the update form
          if(resp.status == 200){
            setDeletedRMDData(null);
            setNotify({
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            });
          }else{
            setNotify({
              isOpen: true,
              message: "Can't delete. Something wrong!!!",
              type: "error",
            });
          }
          
        });
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Can't delete. Something wrong!!!",
        type: "error",
      });
      console.log("error: ", error);
      console.log(error);
    }
  };

  const addNewItem = (value) => {
    const newRMDItem = {
      machine_type: 1,
      rent_value: 1,
    };

    // Update form values with the new RMD item
    formik.setFieldValue("rmd", [...formik.values.rmd, newRMDItem]);
  };

  const handleRemoveRMD = (value) => {
    // Create a new array without the item at the specified index
    if (deletedRMDData?.rowData[0]) {
      deleteRentMachineDetails({ id: deletedRMDData?.rowData[0] });
      const updatedRMD = formik.values.rmd.filter(
        (_, idx) => idx !== value?.rowIndex
      );

      // Update form values with the updated RMD array
      formik.setFieldValue("rmd", updatedRMD);
      return;
    }
    const updatedRMD = formik.values.rmd.filter(
      (_, idx) => idx !== value?.rowIndex
    );

    // Update form values with the updated RMD array
    formik.setFieldValue("rmd", updatedRMD);
  };

  const handleMachineTypeChange = (value, rmdIndex) => {
    // Update the form values with the new "machine_type" for the specified "rmd" item
    formik.setFieldValue(`rmd[${rmdIndex}].machine_type`, value);
  };

  const handleMachineQuantity = (value, rmdIndex) => {
    // Update the form values with the new "start_date" for the specified "rmd" item
    formik.setFieldValue(`rmd[${rmdIndex}].rent_value`, value);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "machine_type",
      label: "Machine Type",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rmdIndex = tableMeta.rowIndex;
          return (
            <>
              <Autocomplete
                id={`rmd[${rmdIndex}].machine_type`}
                options={machineTypeList}
                getOptionLabel={(option) => option.name || ""}
                getOptionSelected={(option, value) => option.id === value}
                value={
                  machineTypeList.find((option) => option.id === value) || null
                }
                onChange={(value, newValue) => {
                  handleMachineTypeChange(newValue ? newValue.id : null, rmdIndex);
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Machine Type"
                    variant="outlined"
                    error={
                      formik.touched[`rmd[${rmdIndex}].machine_type`] &&
                      Boolean(formik.errors[`rmd[${rmdIndex}].machine_type`])
                    }
                    helperText={
                      formik.touched[`rmd[${rmdIndex}].machine_type`] &&
                      formik.errors[`rmd[${rmdIndex}].machine_type`]
                    }
                  />
                )}
                // style={{ width: "450px" }}
                fullWidth
              />
            </>
          );
        },
      },
    },
    {
      name: "rent_value",
      label: "Rent Value",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rmdIndex = tableMeta.rowIndex;
          return (
            <>
              <Controls.Input
                name={`rmd[${rmdIndex}].rent_value`} // Update the name to access the correct "start_date"
                value={value}
                onChange={(e) => {
                  handleMachineQuantity(e.target.value, rmdIndex); // Pass the value and the index of the "rmd" item
                }}
                onBlur={formik.handleBlur}
                fullWidth
              />
            </>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const isLastRow = tableMeta.rowIndex === formik.values.rmd.length - 1;
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              {isLastRow && (
                <IconButton
                  onClick={() => addNewItem(tableMeta)}
                  style={{ backgroundColor: "#019301", color: "white" }}
                  size="small"
                >
                  <AddIcon />
                </IconButton>
              )}
              <IconButton
                color="primary"
                onClick={() => {
                  setDeletedRMDData(tableMeta);
                  setConfirmDeleteRMD(true);
                }}
                style={{
                  backgroundColor: `${
                    tableMeta?.rowIndex === 0 && formik.values.rmd.length === 1
                      ? "#ff000052"
                      : "#e10707"
                  }`,
                  color: "white",
                }}
                size="small"
                disabled={
                  tableMeta?.rowIndex === 0 && formik.values.rmd.length === 1
                }
              >
                <RemoveIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    filterType: "select",
    selectableRows: false,
    responsive: "standard",
    rowsPerPage: [20],
    rowsPerPageOptions: [],
    print: false,
    download: false,
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <React.Fragment>
        <Grid container alignItems="flex-start" spacing={1}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <Box style={{ width: "100%" }}>
              <Grid container alignItems="flex-start" spacing={1}>
                <Grid item md={3} sm={4} xs={6}>
                  <Controls.Input
                    label="Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.Input
                    label="Contact Person"
                    name="contact_person"
                    value={formik.values.contact_person}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.contact_person && Boolean(formik.errors.contact_person)}
                    helperText={formik.touched.contact_person && formik.errors.contact_person}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.Input
                    label="Contact Number"
                    name="contact_number"
                    value={formik.values.contact_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                    helperText={formik.touched.contact_number && formik.errors.contact_number}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.Input
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <Controls.Input
                    label="Address"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                    fullWidth
                  />
                </Grid>

                <Grid md={12} sm={12} xs={12} item style={{ marginTop: 10 }}>
                  {/* Table */}
                  {isLoading ? (
                    <MUIDataTable
                      title={"Line wise machine type"}
                      columns={columns}
                      options={options}
                      className={classes.pageContent}
                    />
                  ) : (
                    <MUIDataTable
                      title={"Line wise machine type"}
                      data={formik.values.rmd}
                      columns={columns}
                      options={options}
                      className={classes.pageContent}
                    />
                  )}

                  <TableCell className={classes.MuiTableCell} />
                </Grid>

                <Grid md={12} sm={4} xs={6} item style={{ marginTop: 10 }}>
                  <div className={classes.wrapper}>
                    <Controls.Button
                      buyer="submit"
                      disabled={formik.isSubmitting}
                      type="submit"
                      text="Submit"
                    />
                    {formik.isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    <Controls.Button
                      text="Reset"
                      color="default"
                      onClick={formik.resetForm}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
            <div>
              <Confirm
                open={confirmDeleteRMD}
                setConfirmDelete={setConfirmDeleteRMD}
                onClose={() => setConfirmDeleteRMD(false)}
                actionText="delete"
                onConfirm={handleRemoveRMD}
                value={deletedRMDData}
              />
            </div>
            <Notification notify={notify} setNotify={setNotify} />
          </MuiThemeProvider>
        </Grid>
      </React.Fragment>
    </Form>
  );
};

export default RMSupplierForm;