import { createTheme } from '@mui/material/styles';

const getMuiTheme = () => createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '2px', // Adjust padding for MuiTableCell
          },
        },
      },

      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: '#50d07d', // Set background color for MUIDataTable header
            color: '#fff', // Set text color for MUIDataTable header
          },
        },
      },
      MuiTableHead: {
        styleOverrides:{
          root: {
              backgroundColor: "red"
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides:{
          root: {
              backgroundColor: "#fff"
          }
        }
      },
    },
    palette: {
      primary: {
        main: '#50d07d', // Set primary color for toolbar background
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#50d07d', // Set background color for MUIDataTableToolbar
          color: '#fff', // Set text color for MUIDataTableToolbar
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#fff', // Set icon color
          '&:hover': {
            color: '#0000ff', // Set icon color on hover
          },
        },
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  export default getMuiTheme