import {
  Box,
  CircularProgress,
  Grid,
  Button,
  IconButton,
  MuiThemeProvider,
  TableCell,
  TextField,
  Tooltip,
  createTheme,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form } from "../../../components/Form/useForm";
import { useFormik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { CustomCircularProgress } from "../../../components/Progress/CustomCircularProgress";
import Controls from "../../../components/Controls/Controls";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import Confirm from "./Confirm";
import Notification from "../../../components/SnackBar/Notification";

const initialFValues = {
  requisition: "",
  supplier: "",
  chalan_no: "",
  rmi: [
    {
      line: "",
      rmil: [
        {
          machine_type: "",
          serial_no: "",
          brand: "",
          rent_cost: "",
          is_machine_return: false,
          rmilm: [
            {
              start_date: new Date(),
              end_date: new Date(),
              total_days: "",
              is_billed: false,
            },
          ],
        },
      ]
    }
  ],
};

const style = makeStyles({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const useButtonStyle = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    borderRadius: '25px',
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
    },
    '&:active': {
      transform: 'translateY(2px)',
    },
  },
  primaryButton: {
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
}));

const RMInventoryForm = (props) => {
  const { addOrEdit, recordForEdit, axiosHeader, isLoading, setIsLoading, rentMachineInventoryList } =
    props;

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          root: {
            backgroundColor: "#50d07d",
            color: "#fff",
          },
          icon: {
            color: "#fff",
            "&:hover": {
              color: "#0000ff",
            },
          },
        },
        MuiTableCell: {
          root: {
            padding: "2px",
            "&:last-child": {
              border: 0,
            },
          },
        },
        MuiTableRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#89898940",
              color: "#fff",
            },
          },
        },
      },

      typography: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
    });
  const [lineList, setLineList] = useState([]);
  const [machineTypeList, setMachineTypeList] = useState([]);

  const [rentMachienRequisitionStyleList, setRentMachienRequisitionStyleList] = useState([]);
  const [rentSupplierList, setRentSupplierList] = useState([]);

  const [confirmDeleteRMI, setConfirmDeleteRMI] = useState(false);
  const [confirmDeleteRMIData, setConfirmDeleteRMIData] = useState(null);

  const [confirmDeleteRMIL, setConfirmDeleteRMIL] = useState(false);
  const [confirmDeleteRMILData, setConfirmDeleteRMILData] = useState(null);

  const [confirmDeleteRMILM, setConfirmDeleteRMILM] = useState(false);
  const [confirmDeleteRMILMData, setConfirmDeleteRMILMData] = useState(null);

  const [requisitionLoading, setRequisitionLoading] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const duplicateChalanCheck = (list, recordForEdit, value)=> {
    var check = true
    if(list.length === 0){
      return check
    }else{
      for (let i=0; i< list.length; i ++){
        if(recordForEdit){
          if( recordForEdit.chalan_no === value){
            return true
          }else{
            if(value === list[i].chalan_no){       
              return check = false
            }
          }
        }else{
          if(value === list[i].chalan_no){       
            return check = false
          }
        }
      }
      return check;
    }
  };

  const validationSchema = Yup.object().shape({
    requisition: Yup.string().required("Requisition is required"),
    supplier: Yup.number().required("Supplier is required"),
    chalan_no: Yup.string().required("Chalan no is required")
      .test("Unique", "Duplicate chalan no", (values) => {
        return duplicateChalanCheck(rentMachineInventoryList, recordForEdit, values)
      }),
    rmi: Yup.array().of(
      Yup.object().shape({
        line: Yup.number().required("Line is required"),
        rmil: Yup.array().of(
          Yup.object().shape({
            machine_type: Yup.number().required("Machine type is required"),
            serial_no: Yup.string().required("Serial No. is required"),
            brand: Yup.string().required("Brand is required"),
            rent_cost: Yup.string().required("Rent value is required"),
            is_machine_return: Yup.boolean(),
            rmilm: Yup.array().of(
              Yup.object().shape({
                start_date: Yup.date().required("Start date is required"),
                end_date: Yup.date().required("End date is required"),
                total_days: Yup.number().required("Total days is required"),
                is_billed: Yup.boolean(),
              })
            ),
          })
        ),
      })
    ),
  });

  const classes = style();
  const btnClasses = useButtonStyle();

  const formik = useFormik({
    initialValues: initialFValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(false);
      addOrEdit(values, resetForm, setSubmitting);
    },
  });

  const sumTotalCost = (values) => {
    let totalCost = 0;
    // Iterate over each element in rmi array
    values.rmi.forEach((rmi) => {
      // Iterate over each element in rmil array
      rmi.rmil.forEach((rmil) => {
        // Iterate over each element in rmilm array
        rmil.rmilm.forEach((rmilm) => {
          // Add total_cost to totalCost variable
          totalCost += parseFloat(rmil.rent_cost * rmilm.total_days) || 0;
        })
      });
    });
  
    return totalCost;
  };

  const sumTotalDue = (values) => {
    let totalDue = 0;
    // Iterate over each element in rmi array
    values.rmi.forEach((rmi) => {
      // Iterate over each element in rmil array
      rmi.rmil.forEach((rmil) => {
        if(!rmil.is_machine_return){
          // Iterate over each element in rmilm array
          rmil.rmilm.forEach((rmilm) => {
            // Add total_cost to totalDue variable
            if(!rmilm.is_billed){
              totalDue += parseFloat(rmil.rent_cost * rmilm.total_days) || 0;
            }
          })
        }
      });
    });
  
    return totalDue;
  };
  
  // Usage
  const totalCost = sumTotalCost(formik.values);
  const totalDue = sumTotalDue(formik.values);

  useEffect(() => {
    if (recordForEdit != null)
      formik.setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);


  useEffect(() => {
    // Set loading state to true
    setIsLoading(true);

    // Line list
    async function getLineList() {
      const response = await fetch("/api/line/list/", axiosHeader);
      const body = await response.json();
      setLineList(body);
    }

    // Machine Teyp list
    async function getMachineTypeList() {
      const response = await fetch("/api/machine/type/list/", axiosHeader);
      const body = await response.json();
      setMachineTypeList(body);
    }

    async function getRentRequisitionStyleList() {
      const response = await fetch("/api/rent-machine-requisition-style-list/", axiosHeader);
      const body = await response.json();
      setRentMachienRequisitionStyleList(body);
    }

    // rent supplier list
    async function getRentSupplier() {
      const response = await fetch(`/api/rent-supplier-list/`, axiosHeader);
      const body = await response.json();
      setRentSupplierList(body);
    }

    // Call all data fetching functions
    Promise.all([
      getMachineTypeList(),
      getLineList(),
      getRentRequisitionStyleList(),
      getRentSupplier(),
    ])
      .then(() => {
        // Set loading state to false once all data fetching is done
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error fetching data:", error);
        // Set loading state to false to stop loading indicator in case of error
        setIsLoading(false);
      });
  }, []);

  const handleRequisitionCall = async () => {
    setRequisitionLoading(true);
    try {
      const response = await fetch(`/api/rent-machine-requisition-load/${!recordForEdit && formik.values.requisition}/`, axiosHeader);
      const body = await response.json();
      console.log("body rmi: ", body[0].total_days)
      if (body != null){
        const transformedData = body[0].rmi.map(item => ({
          line: item.line,
          rmil: item.rmil.map(rmilItem => ({
            machine_type: rmilItem.machine_type,
            serial_no: "",
            brand: "",
            rent_cost: "", //rmilItem.rent_cost
            is_machine_return: false,
            rmilm: [
              {
                start_date: new Date(body[0].start_date),
                end_date: new Date(body[0].end_date),
                total_days: body[0].total_days,
                is_billed: false,
              },
            ],
          })),
        }));

        // Set the transformed data to the form's rmi field
        formik.setValues(prevValues => ({
          ...prevValues,
          rmi: transformedData,
        }))
      }
      // setrequisitionData(body);
      setRequisitionLoading(false);
    } catch (error) {
      console.error('Error calling the API:', error);
      setRequisitionLoading(false);
    }
  };

//----------------------------------------------------------------------------------------------

  const addRMIItem = (value) => {
    const newRMIItem = {
      line: "",
      rmil: [
        {
          machine_type: "",
          serial_no: "",
          brand: "",
          rent_cost: "",
          is_machine_return: false,
          rmilm: [
            {
              start_date: new Date(),
              end_date: new Date(),
              total_days: "",
              is_billed: false,
            },
          ],
        },
      ]
    };

    // Update form values with the new SWM item
    formik.setFieldValue("rmi", [...formik.values.rmi, newRMIItem]);
  };

  const addNewRMIL = (rowData, rmidData, rmidIndex) => {
    const newRMILItem = {
      machine_type: "",
      serial_no: "",
      brand: "",
      rent_cost: "",
      is_machine_return: false,
      rmilm: [
        {
          start_date: new Date(),
          end_date: new Date(),
          total_days: "",
          is_billed: false,
        },
      ],
    };

    try{
      // Update form values with the new RMID item
      formik.setFieldValue(`rmi[${rowData?.rowIndex}].rmil`, [
        ...formik.values.rmi[rowData?.rowIndex].rmil,
        newRMILItem,
      ]);
    }catch(error){
      console.error('An error occurred in rmi.rmil:', error.message);
    }

  };

  const addNewRMILM = (rowData, rmilmData, rmilIndex) => {
    const newRMILMItem = {
      start_date: new Date(),
      end_date: new Date(),
      total_days: "",
      is_billed: false,
    };

    try{
      // Update form values with the new RMID item
      formik.setFieldValue(`rmi[${rowData?.rowIndex}].rmil[${rmilIndex}].rmilm`, [
        ...formik.values.rmi[rowData?.rowIndex].rmil[rmilIndex].rmilm,
        newRMILMItem,
      ]);
    }catch(error){
      console.error('An error occurred in rmi.rmil.rmilm:', error.message);
    }
  };

//----------------------------------------------------------------------------------------------

  const handleRemoveRentMachineLine_RMI = (value) => {
    // Create a new array without the item at the specified index
    if (confirmDeleteRMIData?.rowData[0]) {
      deleteRentMachineInventoryLine({ id: confirmDeleteRMIData?.rowData[0] });
      const updatedRMI = formik.values.rmi.filter(
        (_, idx) => idx !== value?.rowIndex
      );

      // Update form values with the updated RMI array
      formik.setFieldValue("rmi", updatedRMI);
      return;
    }
    const updatedRMI = formik.values.rmi.filter(
      (_, idx) => idx !== value?.rowIndex
    );

    // Update form values with the updated RMI array
    formik.setFieldValue("rmi", updatedRMI);
  };

  const handleRemoveRentMachineLineMachine_RMIL = (value) => {
    const rowData = value?.tableMeta;
    const rmilIndex = value?.rmilIndex;
    console.log("rowData: ", rowData, '--------rmilIndex: ', rmilIndex, '--------value: ', value)
    // Create a new array without the item at the specified index
    const findRMIL = formik.values.rmi[rowData?.rowIndex].rmil.find(
      (_, idx) => idx === rmilIndex
    );
    if (findRMIL) {
      const updatedRMIL = formik.values.rmi[rowData?.rowIndex].rmil.filter(
        (_, idx) => idx !== rmilIndex
      );
      deleteRentMachineInventoryLineMachine({ id: findRMIL?.id });
      // // Update form values with the updated RMIL array
      formik.setFieldValue(`rmi[${rowData?.rowIndex}].rmil`, updatedRMIL);
      return;
    }else{
      const updatedRMIL = formik.values.rmi[rowData?.rowIndex].rmil.filter(
        (_, idx) => idx !== rmilIndex
      );
      // // Update form values with the updated RMIL array
      formik.setFieldValue(`rmi[${rowData?.rowIndex}].rmil`, updatedRMIL);
    }
  };

  const handleRemoveRentMachineLineMachineRunningDetails_RMILM = (value) => {
    // const rmi_index = confirmDeleteRMILMData?.tableMeta?.rowIndex

    const rmi_index = value?.tableMeta?.rowIndex;

    const rmil_index = value?.rmilIndex;

    const rmilmIndex = value?.rmilmIndex;

    const findRMILM = formik.values.rmi[rmi_index].rmil[rmil_index].rmilm.find(
      (_, idx) => idx === rmilmIndex
    );

    if (findRMILM) {
      const updatedRMILM = formik.values.rmi[rmi_index].rmil[rmil_index].rmilm.filter(
        (_, idx) => idx !== rmilmIndex
      );
      deleteRentMachineInventoryLineMachineRunningDetail({ id: findRMILM?.id });
      // Update form values with the updated RMILM array
      formik.setFieldValue(`rmi[${rmi_index}].rmil[${rmil_index}].rmilm`, updatedRMILM);
      return;
    }else{
      const updatedRMILM = formik.values.rmi[rmi_index].rmil[rmil_index].rmilm.filter(
        (_, idx) => idx !== rmilmIndex
      );
      // Update form values with the updated RMILM array
      formik.setFieldValue(`rmi[${rmi_index}].rmil[${rmil_index}].rmilm`, updatedRMILM);
    }
  };

//----------------------------------------------------------------------------------------------

  const deleteRentMachineInventoryLine = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-machine-inventory-line-delete/${values.id}/`, axiosHeader
        )
        .then((resp) => {
          // ToDO: adjust the update form
          setConfirmDeleteRMIData(null);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully Line",
            type: "success",
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteRentMachineInventoryLineMachine = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-machine-inventory-line-machine-delete/${values.id}/`, axiosHeader
        )
        .then((resp) => {
          // ToDO: adjust the update form
          setConfirmDeleteRMILData(null);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully Line Machine",
            type: "success",
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteRentMachineInventoryLineMachineRunningDetail = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-machine-inventory-line-machine-running-detail-delete/${values.id}/`, axiosHeader
        )
        .then((resp) => {
          // ToDO: adjust the update form
          if(resp.status == 200){
            setConfirmDeleteRMILMData(null);
            setNotify({
              isOpen: true,
              message: "Deleted Successfully Line Machine Running Details",
              type: "success",
            });
          }else{
            setNotify({
              isOpen: true,
              message: "Can't delete. Something wrong!!!",
              type: "error",
            });
          }
          
        });
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Can't delete. Something wrong!!!",
        type: "error",
      });
      console.error("error: ", error);
    }
  };

//----------------------------------------------------------------------------------------------

  const updateLineFirstStage = (value, rmiIndex) => {
    // Update the form values with the new "line" for the specified "rmi" item
    formik.setFieldValue(`rmi[${rmiIndex}].line`, value);
  };

  const updateMachineTypeSecondStage = (tableRowIndex, rmilIndex, newMachineType) => {
    // Update the form values with the new "machine_type" for the specified "rmi" item
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];

    updatedRMIL[rmilIndex].machine_type = newMachineType;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;

    formik.setFieldValue(`rmi`, updatedRMI);
  };

  const updateBrandSecondStage = (tableRowIndex, rmilIndex, newBrand) => {
    // Update the form values with the new "brand" for the specified "rmi" item
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];

    updatedRMIL[rmilIndex].brand = newBrand;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;

    formik.setFieldValue(`rmi`, updatedRMI);
  };

  const updateSerialNoSecondStage = (tableRowIndex, rmilIndex, newSerialNo) => {
    // Update the form values with the new "serial_no" for the specified "rmi" item
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];

    updatedRMIL[rmilIndex].serial_no = newSerialNo;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;

    formik.setFieldValue(`rmi`, updatedRMI);
  };
  
  const updateRentCostSecondStage = (tableRowIndex, rmilIndex, newRentCost) => {
    // Update the form values with the new "rent_cost" for the specified "rmi" item
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];

    updatedRMIL[rmilIndex].rent_cost = newRentCost;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;
    
    formik.setFieldValue(`rmi`, updatedRMI);
  };

  const updateMachineReturnSecondStage = (tableRowIndex, rmilIndex, newMachineReturn) => {
    // Update the form values with the new "is_machine_return" for the specified "rmi" item
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];

    updatedRMIL[rmilIndex].is_machine_return = newMachineReturn;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;

    formik.setFieldValue(`rmi`, updatedRMI);
  };

//----------------------------------------------------------------------------------------------

  const updateStartDateThirdStage = (tableRowIndex, rmilIndex, rmilmIndex, newStartDate) => {
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];
    const updatedRMILM = [...updatedRMIL[rmilIndex].rmilm];

    updatedRMILM[rmilmIndex].start_date = newStartDate;
    updatedRMIL[rmilIndex].rmilm = updatedRMILM;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;

    formik.setFieldValue(`rmi`, updatedRMI);
  };

  const updateEndDateThirdStage = (tableRowIndex, rmilIndex, rmilmIndex, newEndDate) => {
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];
    const updatedRMILM = [...updatedRMIL[rmilIndex].rmilm];

    updatedRMILM[rmilmIndex].end_date = newEndDate;
    updatedRMIL[rmilIndex].rmilm = updatedRMILM;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;

    formik.setFieldValue(`rmi`, updatedRMI);
  };

  const updateTotalDaysThirdStage = (tableRowIndex, rmilIndex, rmilmIndex, newTotalDays) => {
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];
    const updatedRMILM = [...updatedRMIL[rmilIndex].rmilm];

    updatedRMILM[rmilmIndex].total_days = newTotalDays;
    updatedRMIL[rmilIndex].rmilm = updatedRMILM;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;

    formik.setFieldValue(`rmi`, updatedRMI);
  };

  const updateIsBilledThirdStage = (tableRowIndex, rmilIndex, rmilmIndex, newIsBilled) => {
    const updatedRMI = [...formik.values.rmi];
    const updatedRMIL = [...updatedRMI[tableRowIndex].rmil];
    const updatedRMILM = [...updatedRMIL[rmilIndex].rmilm];

    updatedRMILM[rmilmIndex].is_billed = newIsBilled;
    updatedRMIL[rmilIndex].rmilm = updatedRMILM;
    updatedRMI[tableRowIndex].rmil = updatedRMIL;

    formik.setFieldValue(`rmi`, updatedRMI);
  };

//----------------------------------------------------------------------------------------------

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "line",
      label: "Line",
      options: {
        filter: false,
        customBodyRender: (lineValue, tableMeta, updateValue) => {
          const rmiIndex = tableMeta.rowIndex;
          return (
            <>
              <Autocomplete
                id={`rmi[${rmiIndex}].line`}
                options={lineList}
                getOptionLabel={(option) => option.name || ""}
                getOptionSelected={(option, value) => option.id === lineValue}
                value={
                  lineList.find((option) => option.id === lineValue) || null
                }
                onChange={(value, newValue) => {
                  updateLineFirstStage(newValue ? newValue.id : null, rmiIndex);
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Line"
                    variant="outlined"
                    error={
                      formik.touched[`rmi[${rmiIndex}].line`] &&
                      Boolean(formik.errors[`rmi[${rmiIndex}].line`])
                    }
                    helperText={
                      formik.touched[`rmi[${rmiIndex}].line`] &&
                      formik.errors[`rmi[${rmiIndex}].line`]
                    }
                    style={{ width: "230px" }}
                  />
                )}
              />
            </>
          );
        },
      },
    },
    {
      name: "rmil",
      label: "Machine Details",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          return (
            <>
              {
                value?.map((rmilItem, rmilIndex) => {
                  const isRMILLastItem = rmilIndex === value.length - 1;

                  return (
                    <div key={rmilIndex} style={{marginBottom: "15px"}}>
                      {
                        rmilItem && (
                          <>
                            <Grid container spacing={1}>
                              <Grid item xs={6} style={{'backgroundColor': '#EBF4FA', 'alignItems': 'left'}}>
                                <Grid container spacing={1}>
                                  <Grid item xs={4}>
                                    <Autocomplete
                                      id={`rmil[${rmilIndex}].machine_type`}
                                      options={machineTypeList}
                                      getOptionLabel={(option) => option.name || ""}
                                      getOptionSelected={(option, value) => option.id === rmilItem?.machine_type}
                                      value={
                                        machineTypeList.find((option) => option.id === rmilItem?.machine_type) || null
                                      }
                                      onChange={(value, newValue) => {
                                        updateMachineTypeSecondStage(tableMeta.rowIndex, rmilIndex, newValue ? newValue.id : null);
                                      }}
                                      onBlur={formik.handleBlur}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Machine Type"
                                          variant="outlined"
                                          error={
                                            formik.touched[`rmil[${rmilIndex}].machine_type`] &&
                                            Boolean(formik.errors[`rmil[${rmilIndex}].machine_type`])
                                          }
                                          helperText={
                                            formik.touched[`rmil[${rmilIndex}].machine_type`] &&
                                            formik.errors[`rmil[${rmilIndex}].machine_type`]
                                          }
                                          // style={{ width: "250px" }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Controls.Input
                                      label="Brand"
                                      name={`rmil[${rmilIndex}].brand`}
                                      type='text'
                                      value={rmilItem?.brand}
                                      onChange={(e) => {
                                        updateBrandSecondStage(tableMeta.rowIndex, rmilIndex, e ? e.target.value : null);
                                      }}
                                      // style={{ width: "170px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={recordForEdit ? 2 : 3}>
                                    <Controls.Input
                                      label="Serial No"
                                      name={`rmil[${rmilIndex}].serial_no`}
                                      type='text'
                                      value={rmilItem?.serial_no}
                                      onChange={(e) => {
                                        updateSerialNoSecondStage(tableMeta.rowIndex, rmilIndex, e ? e.target.value : null);
                                      }}
                                      // style={{ width: "170px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={recordForEdit ? 2 : 3}>
                                    <Controls.Input
                                      label="Rent Cost"
                                      name={`rmil[${rmilIndex}].rent_cost`}
                                      type='number'
                                      value={rmilItem?.rent_cost}
                                      onChange={(e) => {
                                        updateRentCostSecondStage(tableMeta.rowIndex, rmilIndex, e ? e.target.value : null);
                                      }}
                                      // style={{ width: "160px" }}
                                    />
                                  </Grid>
                                  {
                                    recordForEdit ? <Grid item xs={1}>
                                    <Controls.InputCheckBox
                                      label="Is machine return?"
                                      name={`rmil[${rmilIndex}].is_machine_return`}
                                      checked={rmilItem?.is_machine_return}
                                      onChange={(e)=> {
                                        updateMachineReturnSecondStage(tableMeta.rowIndex, rmilIndex, e ? e.target.checked : null);
                                      }}
                                      disabled
                                      // style={{ width: "40px" }}
                                    />
                                  </Grid> : ''
                                  }
                                </Grid>
                              </Grid>
                              <Grid item xs={5} style={{'backgroundColor': '#FDEEF4', 'alignItems': 'right'}}>
                                {
                                  rmilItem?.rmilm?.map((rmilmItem, rmilmIndex) => {
                                    const isRMILMLastItem = rmilmIndex === rmilItem?.rmilm?.length - 1;

                                    return (
                                        rmilmItem && (
                                          <>
                                            <Grid container spacing={1}>
                                              <Grid item xs={recordForEdit ? 3 : 4}>
                                                <Controls.DatePicker
                                                  label="Start Date"
                                                  name={`rmilm[${rmilmIndex}].start_date`} // Update the name to access the correct "start_date"
                                                  value={rmilmItem?.start_date || new Date()}
                                                  onChange={(value) => {
                                                    updateStartDateThirdStage(tableMeta?.rowIndex, rmilIndex, rmilmIndex, value); // Pass the value and the index of the "rmi" item
                                                  }}
                                                  onBlur={formik.handleBlur}
                                                  fullWidth
                                                />
                                              </Grid>
                                              <Grid item xs={recordForEdit ? 3 : 4}>
                                                <Controls.DatePicker
                                                  label="End Date"
                                                  name={`rmilm[${rmilmIndex}].end_date`} // Update the name to access the correct "start_date"
                                                  value={rmilmItem?.end_date || new Date()}
                                                  onChange={(value) => {
                                                    updateEndDateThirdStage(tableMeta?.rowIndex, rmilIndex, rmilmIndex, value); // Pass the value and the index of the "rmi" item
                                                  }}
                                                  onBlur={formik.handleBlur}
                                                  // autoFocus
                                                  fullWidth
                                                />
                                              </Grid>
                                              <Grid item xs={2}>
                                                <Controls.Input
                                                  label="Total Days"
                                                  name={`rmilm[${rmilmIndex}].total_days`}
                                                  value={rmilmItem?.total_days || ""}
                                                  onChange={(e) => {
                                                    updateTotalDaysThirdStage(tableMeta?.rowIndex, rmilIndex, rmilmIndex, Number(e.target.value));
                                                  }}
                                                />
                                              </Grid>
                                              {
                                                recordForEdit ? 
                                                <Grid item xs={2}>
                                                  <Controls.InputCheckBox 
                                                    label="Is billed?"
                                                    name={`rmilm[${rmilmIndex}].is_billed`}
                                                    checked={rmilmItem?.is_billed}
                                                    onChange={(e)=> {
                                                      updateIsBilledThirdStage(tableMeta?.rowIndex, rmilIndex, rmilmIndex, e.target.checked);
                                                    }}
                                                    disabled
                                                  />
                                                </Grid> : <></>
                                              }
                                              <Grid item xs={1}>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    float: "right",
                                                  }}
                                                >
                                                  {isRMILMLastItem && (
                                                    <IconButton
                                                      color="primary"
                                                      onClick={() =>
                                                        addNewRMILM(tableMeta, rmilmItem, rmilIndex)
                                                      }
                                                      style={{
                                                        backgroundColor: "#019301",
                                                        color: "white",
                                                      }}
                                                      size="small"
                                                    >
                                                      <Tooltip title="Add machine running detail" placement="left" arrow enterDelay={100}>
                                                        <AddIcon />
                                                      </Tooltip>
                                                    </IconButton>
                                                  )}

                                                  <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                      // handleRemoveRentMachineLineMachine_RMIL(tableMeta, rmilmItem, rmilmIndex);
                                                      setConfirmDeleteRMILM(true);
                                                      setConfirmDeleteRMILMData({
                                                        tableMeta,
                                                        rmilmItem,
                                                        rmilmIndex,
                                                        rmilIndex,
                                                      });
                                                    }}
                                                    style={{
                                                      backgroundColor: `${
                                                        rmilmIndex === 0 && rmilItem?.rmilm?.length === 1
                                                          ? "#ff000052"
                                                          : "#e10707"
                                                      }`,
                                                      color: "white",
                                                    }}
                                                    disabled={rmilmIndex === 0 && rmilItem?.rmilm?.length === 1}
                                                    size="small"
                                                  >
                                                      <Tooltip title="Remove machine running details" placement="right" arrow enterDelay={500}>
                                                        <RemoveIcon />
                                                      </Tooltip>
                                                  </IconButton>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </>
                                        )
                                      )
                                    }
                                  )
                                }
                              </Grid>
                              <Grid item xs={1}>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    float: "right",
                                  }}
                                >
                                  {isRMILLastItem && (
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        addNewRMIL(tableMeta, rmilItem, rmilIndex)
                                      }
                                      style={{
                                        backgroundColor: "#019301",
                                        color: "white",
                                      }}
                                      size="small"
                                    >
                                      <Tooltip title="Add machine" placement="left" arrow enterDelay={100}>
                                        <AddIcon />
                                      </Tooltip>
                                    </IconButton>
                                  )}

                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      // handleRemoveRentMachineLineMachine_RMIL(tableMeta, rmilmItem, rmilmIndex);
                                      setConfirmDeleteRMIL(true);
                                      setConfirmDeleteRMILData({
                                        tableMeta,
                                        rmilItem,
                                        rmilIndex,
                                      });
                                    }}
                                    style={{
                                      backgroundColor: `${
                                        rmilIndex === 0 && value?.length === 1
                                          ? "#ff000052"
                                          : "#e10707"
                                      }`,
                                      color: "white",
                                    }}
                                    disabled={rmilIndex === 0 && value?.length === 1}
                                    size="small"
                                  >
                                      <Tooltip title="Remove machine" placement="right" arrow enterDelay={500}>
                                        <RemoveIcon />
                                      </Tooltip>
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        )
                      }
                    </div>
                  )
                })
              }
            </>
          )
        }
      }
    },
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const isLastRow = tableMeta.rowIndex === formik.values.rmi.length - 1;
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              {isLastRow && (
                <IconButton
                  onClick={() => addRMIItem(tableMeta)}
                  style={{ backgroundColor: "#019301", color: "white" }}
                  size="small"
                >
                  <Tooltip title="Add Line" placement="left" arrow enterDelay={100}>
                    <AddIcon />
                  </Tooltip>
                </IconButton>
              )}
              <IconButton
                color="primary"
                onClick={() => {
                  setConfirmDeleteRMI(true);
                  setConfirmDeleteRMIData(tableMeta);
                }}
                style={{
                  backgroundColor: `${
                    tableMeta?.rowIndex === 0 && formik.values.rmi.length === 1
                      ? "#ff000052"
                      : "#e10707"
                  }`,
                  color: "white",
                }}
                size="small"
                disabled={
                  tableMeta?.rowIndex === 0 && formik.values.rmi.length === 1
                }
              >
                <Tooltip title="Remove Line" placement="right" arrow enterDelay={100}>
                  <RemoveIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not is_billed the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    filterType: "none",
    selectableRows: 'none',
    responsive: "standard",
    rowsPerPage: [20],
    rowsPerPageOptions: [],
    print: false,
    download: false,
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <React.Fragment>
        <Grid container alignItems="flex-start" spacing={1}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <Box style={{ width: "100%" }}>
              <Grid container alignItems="flex-start" spacing={1}>
                <Grid item md={3} sm={4} xs={6}>
                  <Autocomplete
                    id="requisition"
                    options={rentMachienRequisitionStyleList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Requisition"
                        variant="outlined"
                        error={
                          formik.touched.requisition && Boolean(formik.errors.requisition)
                        }
                        helperText={formik.touched.requisition && formik.errors.requisition}
                      />
                    )}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      rentMachienRequisitionStyleList.find(
                        (requisition) => requisition.id === formik.values.requisition
                      ) ||
                      formik.values.requisition ||
                      null
                    }
                    onChange={(_event, requisition) => {
                      formik.setFieldValue("requisition", requisition ? requisition.id : null);
                    }}
                    disabled={recordForEdit ? true : false}
                    onBlur={formik.handleBlur}
                    // autoFocus
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <Autocomplete
                    id="supplier"
                    options={rentSupplierList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Supplier"
                        variant="outlined"
                        error={
                          formik.touched.supplier && Boolean(formik.errors.supplier)
                        }
                        helperText={formik.touched.supplier && formik.errors.supplier}
                      />
                    )}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      rentSupplierList.find(
                        (supplier) => supplier.id === formik.values.supplier
                      ) ||
                      formik.values.supplier ||
                      null
                    }
                    onChange={(_event, supplier) => {
                      formik.setFieldValue("supplier", supplier ? supplier.id : null);
                    }}
                    disabled={recordForEdit ? true : false}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.Input
                    label="Challan No"
                    name="chalan_no"
                    value={formik.values.chalan_no}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type='text'
                    error={formik.touched.chalan_no && Boolean(formik.errors.chalan_no)}
                    helperText={formik.touched.chalan_no && formik.errors.chalan_no}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRequisitionCall}
                    className={`${btnClasses.button} ${btnClasses.primaryButton}`}
                    disabled={requisitionLoading || recordForEdit}
                  >
                    {requisitionLoading ? <CircularProgress size={24} /> : 'Load Requisition'}
                  </Button>
                </Grid>
                <Grid md={12} sm={4} xs={6} item style={{ marginTop: 10 }}>
                  {isLoading ? (
                    <MUIDataTable
                      title={"Machine details"}
                      columns={columns}
                      options={options}
                      className={classes.pageContent}
                    />
                  ) : (
                    <MUIDataTable
                      title={"Machine details"}
                      data={formik.values.rmi}
                      columns={columns}
                      options={options}
                      className={classes.pageContent}
                    />
                  )}

                  <TableCell className={classes.MuiTableCell} />
                </Grid>

                <Grid item md={4} sm={4} xs={6}></Grid>
                <Grid item md={2} sm={4} xs={6}></Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <div style={{marginRight: '100px', textAlign: 'right'}}>
                    <span style={{fontSize: '20px'}}>Total Cost: </span>
                    <span style={{fontSize: '17px', fontWeight: 'bold', color: 'orange'}}>{totalCost}</span>
                  </div>
                </Grid>
                <Grid item md={3} sm={2} xs={2}>
                  <div style={{marginLeft: '20px', textAlign: 'left'}}>
                    <span style={{fontSize: '20px'}}>Due: </span>
                    <span style={{fontSize: '17px', fontWeight: 'bold', color: 'purple'}}>{totalDue}</span>
                  </div>
                </Grid>

                <Grid md={12} sm={4} xs={6} item style={{ marginTop: 10 }}>
                  <div className={classes.wrapper}>
                    <Controls.Button
                      buyer="submit"
                      disabled={formik.isSubmitting}
                      type="submit"
                      text="Submit"
                    />
                    {formik.isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    <Controls.Button
                      text="Reset"
                      color="default"
                      onClick={formik.resetForm}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
            <div>
              {/* for rmi delete */}
              <Confirm
                open={confirmDeleteRMI}
                setConfirmDelete={setConfirmDeleteRMI}
                onClose={() => setConfirmDeleteRMI(false)}
                actionText="delete"
                onConfirm={handleRemoveRentMachineLine_RMI}
                value={confirmDeleteRMIData}
              />
              {/* for rmil delete */}
              <Confirm
                open={confirmDeleteRMIL}
                setConfirmDelete={setConfirmDeleteRMIL}
                onClose={() => setConfirmDeleteRMIL(false)}
                actionText="delete"
                onConfirm={handleRemoveRentMachineLineMachine_RMIL}
                value={confirmDeleteRMILData}
              />
              {/* for rmilm delete */}
              <Confirm
                open={confirmDeleteRMILM}
                onClose={() => setConfirmDeleteRMILM(false)}
                setConfirmDelete={setConfirmDeleteRMILM}
                actionText="delete"
                onConfirm={handleRemoveRentMachineLineMachineRunningDetails_RMILM}
                value={confirmDeleteRMILMData}
              />
            </div>
            <Notification notify={notify} setNotify={setNotify} />
          </MuiThemeProvider>
        </Grid>
      </React.Fragment>
    </Form>
  );
};

export default RMInventoryForm;