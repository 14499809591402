import React, { useEffect, useState } from "react";
import { Form } from "../../../components/Form/useForm";
import Controls from "../../../components/Controls/Controls";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
} from "@material-ui/core";

const style = makeStyles({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

var initialValues = {
  id: 0,
  machine_type: "",
  price: "",
};

const duplicateNameCheck = (list, recordForEdit, value)=> {
  var check = true
  if(list.length === 0){
    return check
  }else{
    for (let i=0; i< list.length; i ++){
      if(recordForEdit){
        if( recordForEdit.machine_type === value){
          return true
        }else{
          if(value === list[i].machine_type){       
            return check = false
          }
        }
      }else{
        if(value === list[i].machine_type){       
          return check = false
        }
      }
    }
    return check;
  }
};

const RMPriceForm = (props) => {

  const { addOrEdit, recordForEdit, RMPriceList } = props;
  const [types, setTypes] = useState([]);

  const validationSchema = yup.object().shape({
    machine_type: yup.string()
    .required("Machine Type is required")
    .test("Unique", "Machine Type must be unique", (values) => {
      return duplicateNameCheck(RMPriceList, recordForEdit, parseInt(values))
    }),
    price: yup.number().required("Price is required")
  });

  const classes = style();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      addOrEdit(values, resetForm, setSubmitting);
    },
  });

  useEffect(() => {
    if (recordForEdit != null)
      formik.setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  useEffect(() => {
    async function getTypes() {
      const response = await fetch("/api/machine/type/list/", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
      });
      const body = await response.json();
      setTypes(body);
    }
    getTypes();
  },[])


  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Controls.Select
            label="Machine Type"
            name="machine_type"
            value={formik.values.machine_type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={types}
            error={formik.touched.machine_type && Boolean(formik.errors.machine_type)}
            helperText={formik.touched.machine_type && formik.errors.machine_type}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Controls.Input
            label="Price"
            name="price"
            id="price"
            value={formik.values.price}
            onChange={formik.handleChange}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
            fullWidth
          />
        </Grid>

        <Grid item style={{ marginTop: 16 }}>
          <div className={classes.wrapper}>
            <Controls.Button
              type="submit"
              disabled={formik.isSubmitting}
              text="Submit"
            />
            {formik.isSubmitting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            <Controls.Button
              text="Reset"
              color="default"
              onClick={formik.resetForm}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export {
  RMPriceForm,
  duplicateNameCheck,
}