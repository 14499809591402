import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import ApprovalIcon from '@mui/icons-material/Approval';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from "@material-ui/icons/Delete";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import {
  CloudDownload as DownloadIcon, 
} from "@material-ui/icons";
import axios from "axios";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import IconButton from "@material-ui/core/IconButton";
import { Box } from "@material-ui/core";
import { CustomCircularProgress } from "../../../components/Progress/CustomCircularProgress";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import {
  useMachineState,
  useMachineDispatch,
} from "../../../context/index";
import Popup from "../../../components/Controls/Popup";
import Notification from "../../../components/SnackBar/Notification";
import RMRForm from "./RMRForm";

import { makeStyles, TableCell, Tooltip } from "@material-ui/core";
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme from "../../../components/CustomTheme/CustomTheme";
import Confirm from "./Confirm";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  iconButtonColor: {
    color: "#fff",
    "&:hover": {
      color: "#0000ff",
    },
  },
}));

function RMRequisition() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [RMRequisitionRecord, setRMRequisitionRecord] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [MachineList, setMachineList] = useState([]);

  const user_type = localStorage.getItem("user_type");
  const user_id = localStorage.getItem("id");
  const user_receive_requisition_approval = localStorage.getItem("user_receive_requisition_approval");
  const unit = localStorage.getItem("unit");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const AxiosHeader = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  const downloadRequisition = async (values) => {

    try{
      await axios({
        url: `/api/rent-machine-requisition-download/${values?.id}/`,
        method: 'GET',
        responseType: 'blob',
        data: values,
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', 'rent-machine-requisition.pdf');
         document.body.appendChild(link);
         link.click();
      });
    } catch(error){
      console.log(error)
    }
  };

  async function fetchRentMachineRequisition() {
    setIsLoading(true);
    try {
      await axios
        .get(`/api/rent-machine-requisition-list/`, AxiosHeader)
        .then((res) => {
          setMachineList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchRentMachineRequisitionDetails(id) {
    setIsLoading(true);
    try {
      await axios
        .get(`/api/rent-machine-requisition-details/${id}/`, AxiosHeader)
        .then((res) => {
          setRecordForEdit(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const postRentMachineRequisition = async (values, setSubmitting) => {
    try {
      await axios
        .post("/api/rent-machine-requisition-create/", values, AxiosHeader)
        .then((resp) => {
          setRMRequisitionRecord(resp.data);
          setSubmitting(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateRentMachineRequisition = async (values, setSubmitting) => {
    try {
      await axios
        .put(
          `/api/rent-machine-requisition-update/${values.id}/`,
          values,
          AxiosHeader
        )
        .then((resp) => {
          setRMRequisitionRecord(resp.data);
          setSubmitting(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRentMachineRequisition = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-machine-requisition-delete/${values.id}/`, AxiosHeader
        )
        .then((resp) => {
          fetchRentMachineRequisition();
          setDeletedItem(null);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const approveRentMachineRequisition = async (values) => {
    try {
      const response = await fetch(`/api/rent-machine-requisition-approval/${values?.id}/`, {
        method: 'PUT',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const body = await response.json();
      setRMRequisitionRecord(body);
    } catch (error) {
      console.log(error);
    }
  };


  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    fetchRentMachineRequisition();
  }, [RMRequisitionRecord, searchOpen]);

  const openInPopup = (item) => {
    fetchRentMachineRequisitionDetails(item.id);
    // setRecordForEdit(item);
    setOpenPopup(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "buyer_name",
      label: "Buyer Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "style",
      label: "Style",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unit_name",
      label: "Unit Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "plant_name",
    //   label: "Plant Unit",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "requisition_date",
      label: "Requisition Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "start_date_only",
      label: "Start Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "end_date_only",
      label: "End Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "total_days",
      label: "Total Days",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status_name",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let item;
          tableMeta.tableData.forEach(function (fabric) {
            if (fabric.id == tableMeta.rowData[0]) return (item = fabric);
          });
          return (
            <>
              {
                (user_type === "Admin" || user_type === "IE Coordinate" || user_type === "IE Head" ) && item?.status_name != 'IE Head' ?
                  <IconButton
                    color="primary"
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <EditIcon fontSize="medium" />
                  </IconButton> : <></>
              }
              {
                (item?.status_name === 'IE Head') ?
                <IconButton
                  color="primary"
                  onClick={() => {
                    downloadRequisition(item);
                  }}
                >
                  <Tooltip title="Download Requisition" placement="right" arrow enterDelay={100}>
                      <DownloadIcon />
                  </Tooltip>
                </IconButton> : <></>
              }
              {
                (
                  (user_id == item?.created_by) && (item?.status === null)
                ) ?
                <IconButton
                  color="primary"
                  onClick={() => {
                    approveRentMachineRequisition(item);
                  }}
                >
                  <Tooltip title="Send For Approval" placement="right" arrow enterDelay={100}>
                      <SendRoundedIcon />
                    </Tooltip>
                </IconButton> : <></>
              }
              {     
                ( 
                  (item?.status !== null) 
                  && (user_id != item?.created_by)
                  && (user_receive_requisition_approval == item?.status)
                ) ? 
                <IconButton
                  color="primary"
                  onClick={() => {
                    approveRentMachineRequisition(item);
                  }}
                >
                  <Tooltip title="Approve" placement="right" arrow enterDelay={100}>
                    <CheckIcon />
                  </Tooltip>
                </IconButton> : <></>
              }
              {
                user_type === "Admin" ? 
                <IconButton
                  onClick={() => {
                    if (user_type === "Admin") {
                      // deleteRentMachineRequisition(item);
                      setConfirmDelete(true);
                      handleDelete(item);
                    } else {
                      alert("You have no delete permission");
                    }
                  }}
                >
                  <DeleteIcon color="error" fontSize="medium" />
                </IconButton> : <></>
              }
              
              {/* <Link
                to={{
                  pathname: "/app/machine/details",
                  state: item.id,
                }}
              >
                <IconButton color="primary">
                  <ArrowRightAltIcon fontSize="medium" />
                </IconButton>
              </Link> */}
            </>
          );
        },
      },
    },
  ];

  const handleDelete = (item) => {
    setDeletedItem(item);
    setConfirmDelete(true);
  };

  const confirmDeleteMrc = () => {
    if (deletedItem) {
      deleteRentMachineRequisition(deletedItem);
    }
    setConfirmDelete(!confirmDelete);
  };

  const addOrEdit = (style, resetForm, setSubmitting) => {
    console.log("data: ", style);
    if (!style.id) postRentMachineRequisition(style, setSubmitting);
    else updateRentMachineRequisition(style, setSubmitting);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
  };

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    filterType: "select",
    selectableRows: false,
    responsive: "standard",
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100, 200],
    customToolbar: () => {
      return (
        <>
          {
            (user_type === "Admin" || user_type === "IE Coordinate") ? 
            <Tooltip title={"Add New"}>
              <IconButton
                className={classes.iconButtonColor}
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip> : <></>
          }  
        </>
      );
    },
    print: false,
    download: false,
    searchText: searchValue,
    searchOpen: searchOpen,
  };

  return (
    <React.Fragment>
      <Box position="relative">
        <ThemeProvider theme={CustomTheme}>
          <div>
            <BreadCrumb routeSegments={[{ name: "RMR" }]} />
            {isLoading ? (
              <MUIDataTable
                title={"Rent Machine Requisition List"}
                columns={columns}
                options={options}
                className={classes.pageContent}
              />
            ) : (
              <MUIDataTable
                title={"Rent Machine Requisition List"}
                data={MachineList}
                columns={columns}
                options={options}
                className={classes.pageContent}
              />
            )}
            <TableCell className={classes.MuiTableCell} />
            <Popup
              title="Rent Machine Requisition Form"
              width="xl"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <RMRForm
                recordForEdit={recordForEdit}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                addOrEdit={addOrEdit}
                AxiosHeader={AxiosHeader}
              />
            </Popup>
            <Confirm
              open={confirmDelete}
              setConfirmDelete={setConfirmDelete}
              onClose={() => setConfirmDelete(false)}
              actionText="delete"
              onConfirm={confirmDeleteMrc}
            />
            <Notification notify={notify} setNotify={setNotify} />
          </div>
        </ThemeProvider>
      </Box>
    </React.Fragment>
  );
}

export default RMRequisition;