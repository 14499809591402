import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MuiThemeProvider,
  TableCell,
  TextField,
  Tooltip,
  createTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form } from "../../../components/Form/useForm";
import { useFormik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { CustomCircularProgress } from "../../../components/Progress/CustomCircularProgress";
import Controls from "../../../components/Controls/Controls";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import Confirm from "./Confirm";
import Notification from "../../../components/SnackBar/Notification";

const initialFValues = {
  style: "",
  // plant: "",
  buyer: "",
  start_date: new Date(),
  end_date: new Date(),
  order_quantity: "",
  total_days: "",
  rmr: [
    {
      line: "",
      rmrl: [
        {
          rmp: "",
          rent_cost_per_machine: "",
          short_quantity: "",
          total_cost: "",
        },
      ],
    },
  ],
};

const style = makeStyles({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const RMRForm = (props) => {
  const { addOrEdit, recordForEdit, AxiosHeader, isLoading, setIsLoading } =
    props;

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          root: {
            backgroundColor: "#50d07d",
            color: "#fff",
          },
          icon: {
            color: "#fff",
            "&:hover": {
              color: "#0000ff",
            },
          },
        },
        MuiTableCell: {
          root: {
            padding: "2px",
            "&:last-child": {
              border: 0,
            },
          },
        },
        MuiTableRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#89898940",
              color: "#fff",
            },
          },
        },
      },

      typography: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
    });
  const [machineTypeWithCostList, setMachineTypeWithCostList] = useState([]);
  const [productionLine, setProductionLine] = useState([]);
  const [plants, setPlants] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [rentMachienRequisitionStyleList, setRentMachienRequisitionStyleList] = useState([]);
  const [confirmDeleteMrc, setConfirmDeleteMrc] = useState(false);
  const [deletedMrcData, setDeletedMrcData] = useState(null);
  const [confirmDeleteSWl, setConfirmDeleteSWL] = useState(false);
  const [confirmDeleteSwlData, setConfirmDeleteSWlData] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  async function duplicateModelNoCheck(list, value){
    var check = true
    if(list.length === 0){
      return check
    }else{
      for (let i=0; i< list.length; i ++){
        if(recordForEdit){
          if( recordForEdit.style === value){
            return true
          }else{
            if(value === list[i].name){       
              return check = false
            }
          }
        }else{
          if(value === list[i].name){       
            return check = false
          }
        }
      }
      return check;
    }
  }

  const validationSchema = Yup.object().shape({
    style: Yup.string().required("Style is required").test("Unique", "Style no must be unique", (value)=>{
      return duplicateModelNoCheck(rentMachienRequisitionStyleList, value)
    }),
    // plant: Yup.number().required("Plant is required"),
    buyer: Yup.number().required("Buyer is required"),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date().required("End date is required"),
    total_days: Yup.number().required("Total days is required").positive('Total days must be positive').integer('Total days must be an integer'),
    rmr: Yup.array().of(
      Yup.object().shape({
        line: Yup.number().required("Line is required"),
        rmrl: Yup.array().of(
          Yup.object().shape({
            rmp: Yup.number().required("Select Machine type is required"),
            rent_cost_per_machine: Yup.number().required("Cost per machine is required"),
            short_quantity: Yup.number().required("Machine quantity is required").positive('Total days must be positive').integer('Total days must be an integer'),
          })
        ),
      })
    ),
  });

  const classes = style();

  const formik = useFormik({
    initialValues: initialFValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(false);
      addOrEdit(values, resetForm, setSubmitting);
    },
  });

  useEffect(() => {
    if (recordForEdit != null)
      formik.setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const sumTotalCost = (values) => {
    let totalCost = 0;
  
    // Iterate over each element in rmr array
    values.rmr.forEach((item) => {
      // Iterate over each element in rmrl array
      item.rmrl.forEach((subItem) => {
        // Add total_cost to totalCost variable
        totalCost += parseFloat(subItem.rent_cost_per_machine * subItem.short_quantity * values.total_days) || 0;
      });
    });
  
    return totalCost;
  };

  const sumTotalMachine = (values) => {
    let totalCost = 0;
  
    // Iterate over each element in rmr array
    values.rmr.forEach((item) => {
      // Iterate over each element in rmrl array
      item.rmrl.forEach((subItem) => {
        // Add total_cost to totalCost variable
        totalCost += parseInt(subItem.short_quantity) || 0;
      });
    });
  
    return totalCost;
  };
  
  // Usage
  const totalCost = sumTotalCost(formik.values);
  const totalMachine = sumTotalMachine(formik.values);

  useEffect(() => {
    // Set loading state to true
    setIsLoading(true);

    // type list
    async function getMachineTypeWithCostList() {
      const response = await fetch("/api/rent-machine-price-list/", AxiosHeader);
      const body = await response.json();
      setMachineTypeWithCostList(body);
    }

    // production line list
    async function getProductionLine() {
      const response = await fetch("/api/production/line/list/", AxiosHeader);
      const body = await response.json();
      setProductionLine(body);
    }
    // planet List
    async function getPlant() {
      const response = await fetch("/api/plant/list/", AxiosHeader);
      const body = await response.json();
      setPlants(body);
    }
    // buyer list
    async function getBuyers() {
      const response = await fetch("/api/buyer/list/", AxiosHeader);
      const body = await response.json();
      setBuyers(body);
    }

    // type list
    async function getRentRequisitionStyleList() {
      const response = await fetch("/api/rent-machine-requisition-style-list/", AxiosHeader);
      const body = await response.json();
      setRentMachienRequisitionStyleList(body);
    }
    // Call all data fetching functions
    Promise.all([
      getMachineTypeWithCostList(),
      getProductionLine(),
      // getPlant(),
      getBuyers(),
      getRentRequisitionStyleList(),
    ])
      .then(() => {
        // Set loading state to false once all data fetching is done
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error fetching data:", error);
        // Set loading state to false to stop loading indicator in case of error
        setIsLoading(false);
      });
  }, []);

  const deleteStyleWiseLine = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-machine-requisition-line-delete/${values.id}/`, AxiosHeader
        )
        .then((resp) => {
          // ToDO: adjust the update form
          setConfirmDeleteSWlData(null);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteStyleWiseMachineType = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-machine-requisition-line-detail-delete/${values.id}/`, AxiosHeader
        )
        .then((resp) => {
          // ToDO: adjust the update form
          setDeletedMrcData(null);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const addNewItem = () => {
    const newSWMItem = {
      line: "",
      rmrl: [
        {
          rmp: "",
          rent_cost_per_machine: "",
          short_quantity: "",
          total_cost: "",
        },
      ],
    };

    // Update form values with the new SWM item
    formik.setFieldValue("rmr", [...formik.values.rmr, newSWMItem]);
  };

  const handleRemoveMrc = (value) => {
    // Create a new array without the item at the specified index
    if (deletedMrcData?.rowData[0]) {
      deleteStyleWiseLine({ id: deletedMrcData?.rowData[0] });
      const updatedSWM = formik.values.rmr.filter(
        (_, idx) => idx !== value?.rowIndex
      );

      // Update form values with the updated SWM array
      formik.setFieldValue("rmr", updatedSWM);
      return;
    }
    const updatedSWM = formik.values.rmr.filter(
      (_, idx) => idx !== value?.rowIndex
    );

    // Update form values with the updated SWM array
    formik.setFieldValue("rmr", updatedSWM);
  };

  const addNewSWL = (rowData, rmrlData, rmrlIndex) => {
    const newSWLItem = {
      rmp: "",
      rent_cost_per_machine: "",
      short_quantity: "",
      total_cost: "",
    };

    // Update form values with the new SWL item
    formik.setFieldValue(`rmr[${rowData?.rowIndex}].rmrl`, [
      ...formik.values.rmr[rowData?.rowIndex].rmrl,
      newSWLItem,
    ]);
  };

  const removeSwl = (value) => {
    const rowData = value?.tableMeta;
    const rmrlIndex = value?.rmrlIndex;
    // Create a new array without the item at the specified index
    const findSwl = formik.values.rmr[rowData?.rowIndex].rmrl.find(
      (_, idx) => idx === rmrlIndex
    );
    if (findSwl) {
      const updatedSWL = formik.values.rmr[rowData?.rowIndex].rmrl.filter(
        (_, idx) => idx !== rmrlIndex
      );
      deleteStyleWiseMachineType({ id: findSwl?.id });
      // // Update form values with the updated SWL array
      formik.setFieldValue(`rmr[${rowData?.rowIndex}].rmrl`, updatedSWL);
      return;
    }else{
      const updatedSWL = formik.values.rmr[rowData?.rowIndex].rmrl.filter(
        (_, idx) => idx !== rmrlIndex
      );
      // // Update form values with the updated SWL array
      formik.setFieldValue(`rmr[${rowData?.rowIndex}].rmrl`, updatedSWL);
    }
  };

  const handleLineChange = (value, rmrIndex) => {
    // Update the form values with the new "line" for the specified "rmr" item
    formik.setFieldValue(`rmr[${rmrIndex}].line`, value);
  };


  function getRentValueById(id) {
    const supplierCost = machineTypeWithCostList.find(supplierCost => supplierCost.id === id);
    return supplierCost ? supplierCost.price : null;
  }

  const updateRMDetails = (rmrIndex, rmrlIndex, newRMDetail) => {
    const updatedSWM = [...formik.values.rmr];
    const updatedSWL = [...updatedSWM[rmrIndex].rmrl];
    updatedSWL[rmrlIndex].rmp = newRMDetail;
    updatedSWL[rmrlIndex].rent_cost_per_machine = getRentValueById(newRMDetail);
    updatedSWM[rmrIndex].rmrl = updatedSWL;
    formik.setFieldValue(`rmr`, updatedSWM);
  };

  const updateShortQuantity = (rmrIndex, rmrlIndex, newQuantity) => {
    const updatedSWM = [...formik.values.rmr];
    const updatedSWL = [...updatedSWM[rmrIndex].rmrl];
    updatedSWL[rmrlIndex].short_quantity = newQuantity;
    updatedSWM[rmrIndex].rmrl = updatedSWL;
    formik.setFieldValue(`rmr`, updatedSWM);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "line",
      label: "Line",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rmrIndex = tableMeta.rowIndex;
          return (
            <>
              <Autocomplete
                id={`rmr[${rmrIndex}].line`}
                options={productionLine}
                getOptionLabel={(option) => option.name || ""}
                getOptionSelected={(option, value) => option.id === value}
                value={
                  productionLine.find((option) => option.id === value) || null
                }
                onChange={(value, newValue) => {
                  handleLineChange(newValue ? newValue.id : null, rmrIndex);
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Line"
                    variant="outlined"
                    error={
                      formik.touched[`rmr[${rmrIndex}].line`] &&
                      Boolean(formik.errors[`rmr[${rmrIndex}].line`])
                    }
                    helperText={
                      formik.touched[`rmr[${rmrIndex}].line`] &&
                      formik.errors[`rmr[${rmrIndex}].line`]
                    }
                    style={{ width: "250px" }}
                  />
                )}
              />
            </>
          );
        },
      },
    },
    {
      name: "rmrl",
      label: "Rent Machine Details",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value?.map((rmrlItem, rmrlIndex) => {
                const isLastItem = rmrlIndex === value.length - 1;
                const isOddIndex = rmrlIndex % 2 === 1;
                const gridContainerClassName = isOddIndex
                  ? "gray-background"
                  : "";
                return (
                  <div key={rmrlIndex}>
                    {/* Check if "rmrl" and its elements exist before accessing them */}
                    {rmrlItem && (
                      <>
                        <Grid
                          container
                          spacing={1}
                          sx={{ m: 2 }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={3}>
                            <Autocomplete
                              id={`rmrl[${rmrlIndex}].rmp`}
                              options={machineTypeWithCostList}
                              getOptionLabel={(option) => option.name || ""}
                              getOptionSelected={(option, value) =>
                                option.id === value
                              }
                              value={
                                machineTypeWithCostList.find(
                                  (option) => option.id === rmrlItem.rmp
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                updateRMDetails(
                                  tableMeta?.rowIndex,
                                  rmrlIndex,
                                  newValue ? newValue.id : null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  style={{ width: "358px" }}
                                  {...params}
                                  label="Select Machine Type"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Controls.Input
                              label="Cost per machine"
                              name={`rmrl[${rmrlIndex}].rent_cost_per_machine`}
                              value={rmrlItem?.rent_cost_per_machine || ""}
                              type='number'
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Controls.Input
                              label="Required Machine Quantity"
                              name={`rmrl[${rmrlIndex}].short_quantity`}
                              value={rmrlItem?.short_quantity || ""}
                              onChange={(e) => {
                                updateShortQuantity(
                                  tableMeta?.rowIndex,
                                  rmrlIndex,
                                  Number(e.target.value)
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Controls.Input
                              label="Total cost / Day"
                              name={`rmrl[${rmrlIndex}].rent_cost_per_machine`}
                              value={rmrlItem?.rent_cost_per_machine * rmrlItem?.short_quantity || ""}
                              disabled
                              style={{ width: "250px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                float: "right",
                              }}
                            >
                              {isLastItem && (
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    addNewSWL(tableMeta, rmrlItem, rmrlIndex)
                                  }
                                  style={{
                                    backgroundColor: "#019301",
                                    color: "white",
                                  }}
                                  size="small"
                                >
                                  <Tooltip title="Add machine details" placement="left" arrow enterDelay={100}>
                                    <AddIcon />
                                  </Tooltip>
                                </IconButton>
                              )}

                              <IconButton
                                color="primary"
                                onClick={() => {
                                  console.log(tableMeta, rmrlItem, rmrlIndex);
                                  // removeSwl(tableMeta, rmrlItem, rmrlIndex);
                                  setConfirmDeleteSWL(true);
                                  setConfirmDeleteSWlData({
                                    tableMeta,
                                    rmrlItem,
                                    rmrlIndex,
                                  });
                                }}
                                style={{
                                  backgroundColor: `${
                                    rmrlIndex === 0 && value?.length === 1
                                      ? "#ff000052"
                                      : "#e10707"
                                  }`,
                                  color: "white",
                                }}
                                disabled={rmrlIndex === 0 && value?.length === 1}
                                size="small"
                              >
                                  <Tooltip title="Remove machine details" placement="right" arrow enterDelay={100}>
                                    <RemoveIcon />
                                  </Tooltip>
                              </IconButton>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const isLastRow = tableMeta.rowIndex === formik.values.rmr.length - 1;
          return (
            <div style={{ 
                display: "flex", 
                gap: "10px",
                float: "right",
              }}
            >
              {isLastRow && (
                <IconButton
                  onClick={() => addNewItem(tableMeta)}
                  style={{ backgroundColor: "#019301", color: "white" }}
                  size="small"
                >
                  <Tooltip title="Add line" placement="left" arrow enterDelay={100}>
                    <AddIcon />
                  </Tooltip>
                </IconButton>
              )}
              <IconButton
                color="primary"
                onClick={() => {
                  setDeletedMrcData(tableMeta);
                  setConfirmDeleteMrc(true);
                }}
                style={{
                  backgroundColor: `${
                    tableMeta?.rowIndex === 0 && formik.values.rmr.length === 1
                      ? "#ff000052"
                      : "#e10707"
                  }`,
                  color: "white",
                }}
                size="small"
                disabled={
                  tableMeta?.rowIndex === 0 && formik.values.rmr.length === 1
                }
              >
                <Tooltip title="Remove line" placement="right" arrow enterDelay={100}>
                  <RemoveIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    filterType: "select",
    selectableRows: 'none',
    responsive: "standard",
    rowsPerPage: [20],
    rowsPerPageOptions: [],
    print: false,
    download: false,
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <React.Fragment>
        <Grid container alignItems="flex-start" spacing={1}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <Box style={{ width: "100%" }}>
              <Grid container alignItems="flex-start" spacing={1}>
                <Grid item md={2} sm={4} xs={6}>
                  <Autocomplete
                    id="buyer"
                    options={buyers}
                    hide="true"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buyer Name"
                        variant="outlined"
                        error={
                          formik.touched.buyer && Boolean(formik.errors.buyer)
                        }
                        helperText={formik.touched.buyer && formik.errors.buyer}
                      />
                    )}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      buyers.find(
                        (buyer) => buyer.id === formik.values.buyer
                      ) ||
                      formik.values.buyer ||
                      null
                    }
                    onChange={(_event, buyer) => {
                      formik.setFieldValue("buyer", buyer ? buyer.id : null);
                    }}
                    onBlur={formik.handleBlur}
                    // multiple='true'
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.Input
                    label="Style"
                    name="style"
                    value={formik.values.style}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.style && Boolean(formik.errors.style)}
                    helperText={formik.touched.style && formik.errors.style}
                    fullWidth
                  />
                </Grid>
                {/* <Grid item md={2} sm={4} xs={6}>
                  <Autocomplete
                    id="plant"
                    options={plants}
                    hide="true"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Plant"
                        variant="outlined"
                        error={
                          formik.touched.plant && Boolean(formik.errors.plant)
                        }
                        helperText={formik.touched.plant && formik.errors.plant}
                      />
                    )}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      plants.find((line) => line.id === formik.values.plant) ||
                      formik.values.plant ||
                      null
                    }
                    onChange={(_event, plant) => {
                      console.log("🚀 ~ MachineForm ~ plant:", plant);
                      formik.setFieldValue("plant", plant ? plant.id : null);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid> */}
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.Input
                    label="Order Quantity"
                    name="order_quantity"
                    value={formik.values.order_quantity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type='number'
                    error={formik.touched.order_quantity && Boolean(formik.errors.order_quantity)}
                    helperText={formik.touched.order_quantity && formik.errors.order_quantity}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.Input
                    label="Total Days"
                    name="total_days"
                    value={formik.values.total_days}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type='number'
                    error={formik.touched.total_days && Boolean(formik.errors.total_days)}
                    helperText={formik.touched.total_days && formik.errors.total_days}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.DatePicker
                      label="Start Date"
                      name="start_date"
                      value={formik.values.start_date}
                      onChange={value => {
                        formik.setFieldValue("start_date", value)
                      }}
                      onBlur={formik.handleBlur}
                      fullWidth
                    />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Controls.DatePicker
                      label="End Date"
                      name="end_date"
                      value={formik.values.end_date}
                      onChange={value => {
                        formik.setFieldValue("end_date", value)
                      }}
                      onBlur={formik.handleBlur}
                      fullWidth
                    />
                </Grid>
                <Grid md={12} sm={12} xs={12} item style={{ marginTop: 10 }}>
                  {isLoading ? (
                    <MUIDataTable
                      title={"Line wise machine cost estimation"}
                      columns={columns}
                      options={options}
                      className={classes.pageContent}
                    />
                  ) : (
                    <MUIDataTable
                      title={"Line wise machine cost estimation"}
                      data={formik.values.rmr}
                      columns={columns}
                      options={options}
                      className={classes.pageContent}
                    />
                  )}

                  <TableCell className={classes.MuiTableCell} />
                </Grid>

                <Grid item md={4} sm={4} xs={6}></Grid>
                <Grid item md={2} sm={4} xs={6}></Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <div style={{marginRight: '100px', textAlign: 'right'}}>
                    <span style={{fontSize: '20px'}}>Total machine: </span>
                    <span style={{fontSize: '17px', fontWeight: 'bold', color: 'orange'}}>{totalMachine}</span>
                  </div>
                </Grid>
                <Grid item md={3} sm={2} xs={2}>
                  <div style={{marginLeft: '20px', textAlign: 'left'}}>
                    <span style={{fontSize: '20px'}}>Total cost: </span>
                    <span style={{fontSize: '17px', fontWeight: 'bold', color: 'purple'}}>{totalCost}</span>
                  </div>
                </Grid>

                <Grid md={12} sm={4} xs={6} item style={{ marginTop: 10 }}>
                  <div className={classes.wrapper}>
                    <Controls.Button
                      buyer="submit"
                      disabled={formik.isSubmitting}
                      type="submit"
                      text="Submit"
                    />
                    {formik.isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    <Controls.Button
                      text="Reset"
                      color="default"
                      onClick={formik.resetForm}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
            <div>
              <Confirm
                open={confirmDeleteMrc}
                setConfirmDelete={setConfirmDeleteMrc}
                onClose={() => setConfirmDeleteMrc(false)}
                actionText="delete"
                onConfirm={handleRemoveMrc}
                value={deletedMrcData}
              />
              {/* for rmrl delete */}
              <Confirm
                open={confirmDeleteSWl}
                setConfirmDelete={setConfirmDeleteSWL}
                onClose={() => setConfirmDeleteSWL(false)}
                actionText="delete"
                onConfirm={removeSwl}
                value={confirmDeleteSwlData}
              />
            </div>
            <Notification notify={notify} setNotify={setNotify} />
          </MuiThemeProvider>
        </Grid>
      </React.Fragment>
    </Form>
  );
};

export default RMRForm;