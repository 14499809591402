import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import {
  CloudDownload as DownloadIcon, 
} from "@material-ui/icons";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ApprovalIcon from '@mui/icons-material/Approval';
import { Box } from "@material-ui/core";
import { CustomCircularProgress } from "../../../components/Progress/CustomCircularProgress";
import DehazeIcon from '@mui/icons-material/Dehaze';
import Popup from "../../../components/Controls/Popup";
import Notification from "../../../components/SnackBar/Notification";
import RMSupplierForm from "./RMBillingForm";

import { makeStyles, TableCell, Tooltip } from "@material-ui/core";
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme from "../../../components/CustomTheme/CustomTheme";
import Confirm from "./Confirm";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  iconButtonColor: {
    color: "#fff",
    "&:hover": {
      color: "#0000ff",
    },
  },
}));

function RMBilling() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [rentBillingRecord, setRentBillingRecord] = useState([]);
  const [rentBillingList, setRentBillingList] = useState([]);
  const user_type = localStorage.getItem("user_type");
  const user_id = localStorage.getItem("id");
  const user_receive_billing_approval = localStorage.getItem("user_receive_billing_approval");

  const [openRentMachineDetailsPopup, setOpenRentMachineDetailsPopup] = useState(false);


  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const AxiosHeader = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  async function fetchRentBilling() {
    setIsLoading(true);
    try {
      await axios
        .get(`/api/rent-billing-list/`, AxiosHeader)
        .then((res) => {
          setRentBillingList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchStyleWiseMachineDetail(id) {
    setIsLoading(true);
    try {
      await axios
        .get(`/api/rent-billing-details/${id}/`, AxiosHeader)
        .then((res) => {
          setRecordForEdit(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const postRentMachineSupplier = async (values, setSubmitting) => {
    try {
      await axios
        .post("/api/rent-billing-create/", values, AxiosHeader)
        .then((resp) => {
          setRentBillingRecord(resp.data);
          // alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateRentMachineSupplier = async (values, setSubmitting) => {
    try {
      await axios
        .put(
          `/api/rent-billing-update/${values.id}/`,
          values,
          AxiosHeader
        )
        .then((resp) => {
          setRentBillingRecord(resp.data);
          // alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRMSupplier = async (values) => {
    try {
      await axios
        .delete(
          `/api/rent-billing-delete/${values.id}/`, AxiosHeader
        )
        .then((resp) => {
          fetchRentBilling();
          setDeletedItem(null);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadBilling = async (values) => {

    try{
      await axios({
        url: `/api/rent-billing-download/${values?.id}/`,
        method: 'GET',
        responseType: 'blob',
        data: values,
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', 'rent-machine-bill.pdf');
         document.body.appendChild(link);
         link.click();
      });
    } catch(error){
      console.log(error)
    }
  };


  const approveRentMachineBilling = async (values) => {
    try {
      const response = await fetch(`/api/rent-billing-approval/${values?.id}/`, {
        method: 'PUT',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const body = await response.json();
      setRentBillingRecord(body);
    } catch (error) {
      console.log(error);
    }
  };



  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    fetchRentBilling();
  }, [rentBillingRecord, searchOpen]);

  const openInPopup = (item) => {
    fetchStyleWiseMachineDetail(item.id);
    // setRecordForEdit(item);
    setOpenPopup(true);
  };
  const openInDetailsPopup = (item) => {
    fetchStyleWiseMachineDetail(item?.id)
    setOpenRentMachineDetailsPopup(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "unit",
      label: "Unit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Bill No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "supplier_name",
      label: "Supplier",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "supplier_bill_no",
      label: "Supplier Bill No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "get_billing_month_name",
      label: "Billing month",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let item;
          tableMeta.tableData.forEach(function (fabric) {
            if (fabric.id == tableMeta.rowData[0]) return (item = fabric);
          });
          return (
            <>  
              {
                (user_type === "Admin" || user_type === "Maintenance Coordinate") ? 
                <IconButton 
                color="primary"
                onClick={() => {
                  openInPopup(item);
                }}
              >
                <EditIcon fontSize="medium" />
              </IconButton> : <></>
              }
              {
                (
                  (user_id == item?.created_by) && (item?.status === null)
                ) ?
                <IconButton
                  color="primary"
                  onClick={() => {
                    approveRentMachineBilling(item);
                  }}
                >
                  <Tooltip title="Send For Approval" placement="right" arrow enterDelay={100}>
                    <SendRoundedIcon />
                  </Tooltip>
                </IconButton> : <></>
              }
              {     
                ( 
                  (item?.status !== null) 
                  && (user_id != item?.created_by)
                  && (user_receive_billing_approval == item?.status)
                ) ? 
                <IconButton
                  color="primary"
                  onClick={() => {
                    approveRentMachineBilling(item);
                  }}
                >
                  <Tooltip title="Approve" placement="right" arrow enterDelay={100}>
                    <ApprovalIcon />
                  </Tooltip>
                </IconButton> : <></>
              }
              {
                (item?.status_name === 'Maintenance Head') ?
              <IconButton
                color="primary"
                onClick={() => {
                  downloadBilling(item);
                }}
              >
                <Tooltip title="Download Bill" placement="right" arrow enterDelay={100}>
                    <DownloadIcon />
                </Tooltip>
              </IconButton> : <></>
              }

              {
                (
                  (((user_id == item?.created_by) && (item?.status === null)) || user_type === "Admin")
                ) ?
                <IconButton
                onClick={() => {
                  if (user_type === "Admin" || user_type === "Maintenance Coordinate") {
                    setConfirmDelete(true);
                    handleDelete(item);
                  } else {
                    alert("You have no delete permission");
                  }
                }}
              >
                <DeleteIcon color="error" fontSize="medium" />
              </IconButton> : <></>
              }
              {/* <Link
                to={{
                  pathname: "/app/machine/details",
                  state: item.id,
                }}
              >
                <IconButton color="primary">
                  <ArrowRightAltIcon fontSize="medium" />
                </IconButton>
              </Link> */}
              {/* <IconButton
                color="primary"
                onClick={() => {
                  openInDetailsPopup(item);
                }}
              >
                <DehazeIcon fontSize="medium" style={{'color': 'purple'}} />
              </IconButton> */}
            </>
          );
        },
      },
    },
  ];

  const detailColumns = [
    {
      name: "machine_type_name",
      label: "Machine Type",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "rent_value",
      label: "Rent Value",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ]

  const handleDelete = (item) => {
    setDeletedItem(item);
    setConfirmDelete(true);
  };

  const confirmDeleteRMS = () => {
    if (deletedItem) {
      deleteRMSupplier(deletedItem);
    }
    setConfirmDelete(!confirmDelete);
  };

  const addOrEdit = (rms, resetForm, setSubmitting) => {
    if (!rms.id) postRentMachineSupplier(rms, setSubmitting);
    else updateRentMachineSupplier(rms, setSubmitting);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
  };

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    filterType: "select",
    selectableRows: 'none', //multiple | single | none 
    responsive: "standard",
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100, 200],
    customToolbar: () => {
      return (
        <>
        {
          (user_type === "Admin" || user_type === "Maintenance Coordinate") ? 
          <Tooltip title={"Add New"}>
            <IconButton
              className={classes.iconButtonColor}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip> : <></>
        }
        </>
      );
    },
    print: false,
    download: false,
    searchText: searchValue,
    searchOpen: searchOpen,
  };

  const detailOptions = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    // rowsPerPage: [],
    // rowsPerPageOptions: [],
    filter: false,
    selectableRows: 'none', //multiple | single | none
    print: false,
    download: false,
  }

  return (
    <React.Fragment>
      <Box position="relative">
        <ThemeProvider theme={CustomTheme}>
          <div>
            <BreadCrumb routeSegments={[{ name: "Rent Machine Billing" }]} />
            {isLoading ? (
              <MUIDataTable
                title={"Rent Machine Billing List"}
                columns={columns}
                options={options}
                className={classes.pageContent}
              />
            ) : (
              <MUIDataTable
                title={"Rent Machine Billing List"}
                data={rentBillingList}
                columns={columns}
                options={options}
                className={classes.pageContent}
              />
            )}
            <TableCell className={classes.MuiTableCell} />
            <Popup
              title="Rent Machine Billing Form"
              width="xl"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <RMSupplierForm
                recordForEdit={recordForEdit}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                addOrEdit={addOrEdit}
                AxiosHeader={AxiosHeader}
              />
            </Popup>
            <Confirm
              open={confirmDelete}
              setConfirmDelete={setConfirmDelete}
              onClose={() => setConfirmDelete(false)}
              actionText="delete"
              onConfirm={confirmDeleteRMS}
            />

            <Popup
              title="Rent Machine Billing Details"
              width="xl"
              openPopup={openRentMachineDetailsPopup}
              setOpenPopup={setOpenRentMachineDetailsPopup}
            >
              <MUIDataTable
                data={recordForEdit?.rmd}
                columns={detailColumns}
                options={detailOptions}
                className={classes.pageContent}
              />
            </Popup>
            <Notification notify={notify} setNotify={setNotify} />
          </div>
        </ThemeProvider>
      </Box>
    </React.Fragment>
  );
}

export default RMBilling;