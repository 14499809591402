import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";


// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Machines from "../../pages/machines/Machines";
import RejectedMachines from "../../pages/machines/rejectedMachine/RejectedMachines";
import AddMachineToLine from "../../pages/machineMovement/AddMachineToLine";
import ProductionLine from "../../pages/machineMovement/productionLine/ProductionLine";
import SupportLine from "../../pages/machineMovement/supportLine/SupportLine"
import LineName from "../../pages/machineMovement/LineName";
import MachineDetail from "../../pages/machines/MachineDetail";
import RejectedMachineDetail from "../../pages/machines/rejectedMachine/RejectedMachineDetail";
import Units from "../../pages/units/Units";
import UnitForm from "../../pages/units/UnitForm"
import Typography from "../../pages/typography";
import Tables from "../../pages/tables";
import MachineHistory from "../../pages/reports/MachineHistory";
import FindMachinePosition from "../../pages/reports/FindMachinePosition";
import Profile from "../../pages/profile/Profile";
import MachineCategoryType from "../../pages/machines/MachineCategoryType";
import LineNameCategory from "../../pages/machineMovement/LineNameCategory";
import MachineRent from "../../pages/machines/MachineRent";

//Transfer
import MCMachineOut from '../../pages/machineTransfer/out/maintenanceCoordinate/MCMachineOut';
import MHMachineOutApproval from '../../pages/machineTransfer/out/maintenanceHead/MHMachineOutApproval';
import IEMachineOut from '../../pages/machineTransfer/out/ieHead/IEMachineOut';
import SMMachineOutApproval from '../../pages/machineTransfer/out/StoreManager/SMMachineOutApproval';

import SMMachineInApproval from '../../pages/machineTransfer/in/StoreManager/SMMachineInApproval';
import IEMachineInApproval from '../../pages/machineTransfer/in/IeHead/IEMachineInApproval';
import MHMachineInApproval from '../../pages/machineTransfer/in/maintenanceHead/MHMachineInApproval';

//MRC
import MRC from "../../pages/MRC/MRC";

// Rent Machine
import RMPrice from "../../pages/RentMachine/RMPrice/RMPrice";
import RMSupplier from "../../pages/RentMachine/RMSupplier/RMSupplier";
import RMRequisition from "../../pages/RentMachine/RMRequisition/RMRequisition";
import RMInventory from "../../pages/RentMachine/RMInventory/RMInventory";
import RMInventoryMachineList from "../../pages/RentMachine/RMInventory/RMInventoryMachineList";

// Rent Machine Billing
import RMBilling from "../../pages/RentMachine/RMBilling/RMBilling";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <div>
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/machines" component={Machines} />
            <Route path="/app/rejected/machines" component={RejectedMachines} />
            {/* <Route path="/app/AddMachineToLine" component={AddMachineToLine} /> */} 
            <Route path="/app/line" component={ProductionLine} />
            <Route path="/app/productionLine" component={ProductionLine} />
            <Route path="/app/supportLine" component={SupportLine} />
            <Route path="/app/line-name" component={LineName} />
            <Route path="/app/machine/details" component={MachineDetail} />
            <Route path="/app/rejected/machine/details" component={RejectedMachineDetail} />
            <Route path="/app/units" component={Units} />
            <Route path="/app/unit/form" component={UnitForm}/>
            <Route path="/app/machine/history/report" component={MachineHistory}/>
            <Route path="/app/find/machine/" component={FindMachinePosition}/>
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/profile" component={Profile} />
            <Route path="/app/machine/categoriesTypes" component={MachineCategoryType} />
            <Route path="/app/linECategoryName" component={LineNameCategory} />
            <Route path="/app/rent/machine" component={MachineRent} />
            <Route exact path="/app/ui" render={() => <Redirect to="/app/ui/icons" />} />
            {/* Transfer */}
            <Route path="/app/transfer/mc/machine/out/" component={MCMachineOut} />
            <Route path="/app/transfer/ie/machine/out/" component={IEMachineOut} />
            <Route path="/app/transfer/mh/machine/out/approval/" component={MHMachineOutApproval} />
            <Route path="/app/transfer/sm/machine/out/approval/" component={SMMachineOutApproval} />

            <Route path="/app/transfer/sm/machine/in/approval/" component={SMMachineInApproval} />
            <Route path="/app/transfer/ie/machine/in/approval/" component={IEMachineInApproval} />
            <Route path="/app/transfer/mh/machine/in/approval/" component={MHMachineInApproval} />

            {/* MRC */}
            <Route path="/app/mrc" component={MRC} />
            {/* Rent Machine */}
            <Route path="/app/rentmachineprice" component={RMPrice} />
            <Route path="/app/rmsupplier" component={RMSupplier} />
            <Route path="/app/rmrequisition" component={RMRequisition} />
            <Route path="/app/rminventory" component={RMInventory} />
            <Route path="/app/rmimachinelist" component={RMInventoryMachineList} />

            {/* Rent Machine Billing */}
            <Route path="/app/rmbilling" component={RMBilling} />

          </Switch>
          <Footer />
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
