import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  LineStyle as LineStyleIcon, 
  LineWeight as LineWeightIcon,
  AcUnit as AcUnitIcon,
  Transform as TransformIcon,
  ArrowForward as ArrowForwardIcon,
  Download as DownloadIcon
} from "@material-ui/icons";
import SettingsIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const admin = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Machines",
    link: "/app/machines",
    icon: <ViewModuleIcon />,
  },
  {
    id: 2,
    label: "Units",
    link: "/app/units",
    icon: <AcUnitIcon />,
  },
  {
    id: 3,
    label: "Line",
    link: "/app/line",
    icon: <LineStyleIcon />,
    children: [
      { label: "Production Line Machines", link: "/app/productionLine" },
      { label: "Support Line Machines", link: "/app/supportLine" },
    ],
  },
  {
    id: 4,
    label: "MRC",
    link: "/app/mrc",
    icon: <LineStyleIcon />,
    children: [
      { label: "MRF", link: "/app/mrc" },
    ],
  },
  {
    id: 4,
    label: "Rent Machine",
    link: "/app/rmsupplier",
    icon: <LineStyleIcon />,
    children: [
      { label: "Rent Machine Price", link: "/app/rentmachineprice" },
      { label: "Rent Supplier", link: "/app/rmsupplier" },
      { label: "Rent Machine Requisition", link: "/app/rmrequisition" },
      { label: "Rent Machine Inventory", link: "/app/rminventory" },
      { label: "Rent Machine List", link: "/app/rmimachinelist" },
      { label: "Rent Machine Billing", link: "/app/rmbilling" },
    ],
  },
  {
    id: 5,
    label: "Transfer",
    link: "/app/transfer",
    icon: <TransformIcon />,
    children: [
      { 
        id: 1,
        label: "Out", 
        link: "/app/transfer/machine/out/",
        icon: <ArrowBackIcon />,
        children: [
          { label: "Maintenance Coordinate Out", link: "/app/transfer/mc/machine/out/" },
          { label: "IE Head Out Approval", link: "/app/transfer/ie/machine/out/" },
          { label: "Maintenance Head Out Approval", link: "/app/transfer/mh/machine/out/approval/" },
          { label: "Store Manager Out Approval", link: "/app/transfer/sm/machine/out/approval/" },
        ],
      },
      { 
        id: 2,
        label: "In", 
        link: "/app/transfer/machine/in/",
        icon: <ArrowForwardIcon />,
        children: [
          { label: "Store Manager In Approval", link: "/app/transfer/sm/machine/in/approval/" },
          { label: "Maintenance Head In Approval", link: "/app/transfer/mh/machine/in/approval/" },
          { label: "IE Head In Approval", link: "/app/transfer/ie/machine/in/approval/" },
        ],
      },
    ],
  },
  {
    id: 6,
    label: "Settings",
    link: "/app/machine/rentCategoriesTypes",
    icon: <SettingsIcon />,
    children: [
      { label: "Type, Category", link: "/app/machine/categoriesTypes" },
      { label: "Line Name & Category", link: "/app/linECategoryName" },
      { label: "Rent Machines", link: "/app/rent/machine" },
      { label: "Rejected Machines", link: "/app/rejected/machines" },
    ],
  },
  {
    id: 7,
    label: "Reports",
    link: "/app/report",
    icon: <AssessmentIcon />,
    children: [
      // { label: "Find Machine", link: "/app/find/machine/" },
      { label: "Machine History Report", link: "/app/machine/history/report" },
      { label: "Breakdown Maintenance Report", link: "/app/report" },
      { label: "Preventive Maintenance Report", link: "/app/report" },
    ],
  },
];

const structure_maintenance_coordinate = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Machines",
    link: "/app/machines",
    icon: <ViewModuleIcon />,
  },
  {
    id: 3,
    label: "Units",
    link: "/app/units",
    icon: <AcUnitIcon />,
  },
  {
    id: 5,
    label: "Line",
    link: "/app/line-task",
    icon: <LibraryIcon />,
    children: [
      { label: "Production Line Machines", link: "/app/productionLine" },
      { label: "Support Line Machines", link: "/app/supportLine" },
    ],
  },
  {
    id: 4,
    label: "Transfer",
    link: "/app/transfer/mc/machine/out/",
    icon: <TransformIcon />,
  },
  {
    id: 5,
    label: "Rent Machine",
    link: "/app/rmsupplier",
    icon: <LineStyleIcon />,
    children: [
      { label: "Rent Machine Price", link: "/app/rentmachineprice" },
      { label: "Rent Supplier", link: "/app/rmsupplier" },
      { label: "Rent Machine Requisition", link: "/app/rmrequisition" },
      { label: "Rent Machine Inventory", link: "/app/rminventory" },
      { label: "Rent Machine List", link: "/app/rmimachinelist" },
      { label: "Rent Machine Billing", link: "/app/rmbilling" },
    ],
  },
  {
    id: 6,
    label: "Settings",
    link: "/app/machine/rentCategoriesTypes",
    icon: <SettingsIcon />,
    children: [
      { label: "Type, Category", link: "/app/machine/categoriesTypes" },
      { label: "Line Name & Category", link: "/app/linECategoryName" },
      { label: "Rent Machines", link: "/app/rent/machine" },
      { label: "Rejected Machines", link: "/app/rejected/machines" },
    ],
  },
  {
    id: 7,
    label: "Reports",
    link: "/app/report",
    icon: <AssessmentIcon />,
    children: [
      // { label: "Find Machine", link: "/app/find/machine/" },
      { label: "Machine History Report", link: "/app/machine/history/report" },
      { label: "Breakdown Maintenance Report", link: "/app/report" },
      { label: "Preventive Maintenance Report", link: "/app/report" },
    ],
  },
];

const structure_maintenance_head = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Machines",
    link: "/app/machines",
    icon: <ViewModuleIcon />,
  },
  {
    id: 2,
    label: "Units",
    link: "/app/units",
    icon: <AcUnitIcon />,
  },
  {
    id: 4,
    label: "Line",
    link: "/app/line",
    icon: <LineStyleIcon />,
    children: [
      { label: "Production Line Machines", link: "/app/productionLine" },
      { label: "Support Line Machines", link: "/app/supportLine" },
    ],
  },
  {
    id: 4,
    label: "Transfer",
    link: "/app/transfer/mh/machine/out/approval/",
    icon: <TransformIcon />,
    children: [
      { 
        id: 1,
        label: "Out", 
        link: "/app/transfer/mh/machine/out/approval/",
        icon: <ArrowBackIcon />,
      },
      { 
        id: 2,
        label: "In", 
        link: "/app/transfer/mh/machine/in/approval/",
        icon: <ArrowForwardIcon />,
      },
    ],
  },
  {
    id: 4,
    label: "Rent Machine",
    link: "/app/rmsupplier",
    icon: <LineStyleIcon />,
    children: [
      { label: "Rent Machine Price", link: "/app/rentmachineprice" },
      { label: "Rent Supplier", link: "/app/rmsupplier" },
      { label: "Rent Machine Requisition", link: "/app/rmrequisition" },
      { label: "Rent Machine Inventory", link: "/app/rminventory" },
      { label: "Rent Machine List", link: "/app/rmimachinelist" },
      { label: "Rent Machine Billing", link: "/app/rmbilling" },
    ],
  },
  {
    id: 5,
    label: "Settings",
    link: "/app/machine/rentCategoriesTypes",
    icon: <SettingsIcon />,
    children: [
      { label: "Type, Category", link: "/app/machine/categoriesTypes" },
      { label: "Line Name & Category", link: "/app/linECategoryName" },
      { label: "Rent Machines", link: "/app/rent/machine" },
      { label: "Rejected Machines", link: "/app/rejected/machines" },
    ],
  },
  {
    id: 6,
    label: "Reports",
    link: "/app/report",
    icon: <AssessmentIcon />,
    children: [
      // { label: "Find Machine", link: "/app/find/machine/" },
      { label: "Machine History Report", link: "/app/machine/history/report" },
      { label: "Breakdown Maintenance Report", link: "/app/report" },
      { label: "Preventive Maintenance Report", link: "/app/report" },
    ],
  },
];

const structure_ie_head = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Machines",
    link: "/app/machines",
    icon: <ViewModuleIcon />,
  },
  {
    id: 2,
    label: "Units",
    link: "/app/units",
    icon: <AcUnitIcon />,
  },
  {
    id: 3,
    label: "Line",
    link: "/app/line",
    icon: <LineStyleIcon />,
    children: [
      { label: "Production Line Machines", link: "/app/productionLine" },
      { label: "Support Line Machines", link: "/app/supportLine" },
    ],
  },
  {
    id: 4,
    label: "Rent Machine",
    link: "/app/rmsupplier",
    icon: <LineStyleIcon />,
    children: [
      { label: "Rent Machine Requisition", link: "/app/rmrequisition" },
      { label: "Rent Machine List", link: "/app/rmimachinelist" },
      { label: "Rent Machine Billing", link: "/app/rmbilling" },
    ],
  },
  {
    id: 5,
    label: "Transfer",
    link: "/app/transfer/ie/machine/out/",
    icon: <TransformIcon />,
    children: [
      { 
        id: 1,
        label: "Out", 
        link: "/app/transfer/ie/machine/out/",
        icon: <ArrowBackIcon />,
      },
      { 
        id: 2,
        label: "In", 
        link: "/app/transfer/ie/machine/in/approval/",
        icon: <ArrowForwardIcon />,
      },
    ],
  },
  {
    id: 6,
    label: "Settings",
    link: "/app/machine/rentCategoriesTypes",
    icon: <SettingsIcon />,
    children: [
      { label: "Type, Category", link: "/app/machine/categoriesTypes" },
      { label: "Line Name & Category", link: "/app/linECategoryName" },
      { label: "Rent Machines", link: "/app/rent/machine" },
      { label: "Rejected Machines", link: "/app/rejected/machines" },
    ],
  },
  {
    id: 7,
    label: "Reports",
    link: "/app/report",
    icon: <AssessmentIcon />,
    children: [
      // { label: "Find Machine", link: "/app/find/machine/" },
      { label: "Machine History Report", link: "/app/machine/history/report" },
      { label: "Breakdown Maintenance Report", link: "/app/report" },
      { label: "Preventive Maintenance Report", link: "/app/report" },
    ],
  },
];

const structure_store_manager = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Machines",
    link: "/app/machines",
    icon: <ViewModuleIcon />,
  },
  {
    id: 3,
    label: "Units",
    link: "/app/units",
    icon: <AcUnitIcon />,
  },
  {
    id: 4,
    label: "Transfer",
    link: "/app/transfer/sm/machine/out/approval/",
    icon: <TransformIcon />,
    children: [
      { 
        id: 1,
        label: "Out", 
        link: "/app/transfer/sm/machine/out/approval/",
        icon: <ArrowBackIcon />,
      },
      { 
        id: 2,
        label: "In", 
        link: "/app/transfer/sm/machine/in/approval/",
        icon: <ArrowForwardIcon />,
      },
    ],
  },
  {
    id: 6,
    label: "Reports",
    link: "/app/report",
    icon: <AssessmentIcon />,
    children: [
      // { label: "Find Machine", link: "/app/find/machine/" },
      { label: "Machine History Report", link: "/app/machine/history/report" },
      { label: "Breakdown Maintenance Report", link: "/app/report" },
      { label: "Preventive Maintenance Report", link: "/app/report" },
    ],
  },
];

const structure_ie_coordinate = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Machines",
    link: "/app/machines",
    icon: <ViewModuleIcon />,
  },
  {
    id: 3,
    label: "Units",
    link: "/app/units",
    icon: <AcUnitIcon />,
  },
  {
    id: 4,
    label: "MRC",
    link: "/app/mrc",
    icon: <LineStyleIcon />,
    children: [
      { label: "MRF", link: "/app/mrc" },
    ],
  },
  {
    id: 4,
    label: "Rent Machine",
    link: "/app/rmsupplier",
    icon: <LineStyleIcon />,
    children: [
      { label: "Rent Machine Requisition", link: "/app/rmrequisition" },
      { label: "Rent Machine List", link: "/app/rmimachinelist" },
      { label: "Rent Machine Billing", link: "/app/rmbilling" },
    ],
  },
  {
    id: 5,
    label: "Reports",
    link: "/app/report",
    icon: <AssessmentIcon />,
    children: [
      // { label: "Find Machine", link: "/app/find/machine/" },
      { label: "Machine History Report", link: "/app/machine/history/report" },
      { label: "Breakdown Maintenance Report", link: "/app/report" },
      { label: "Preventive Maintenance Report", link: "/app/report" },
    ],
  },
];

const structure_ho_accounts = [
  {
    id: 4,
    label: "Rent Machine",
    link: "/app/rmbilling",
    icon: <LineStyleIcon />,
  },
];


function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

let structure;

const user_type = localStorage.getItem("user_type");
if(user_type === "IE Head"){
  structure = structure_ie_head
}else if(user_type === "Maintenance Head"){
  structure = structure_maintenance_head
}else if(user_type === "Maintenance Coordinate"){
  structure = structure_maintenance_coordinate
}else if(user_type === "Store Manager"){
  structure = structure_store_manager
}else if(user_type === "IE Coordinate"){
  structure = structure_ie_coordinate
}else if(user_type === "Head Office Accounts"){
  structure = structure_ho_accounts
}else{
  structure = admin
}
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
