import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import { Box } from "@material-ui/core";
import { CustomCircularProgress } from "../../../components/Progress/CustomCircularProgress";
import DehazeIcon from '@mui/icons-material/Dehaze';
import Popup from "../../../components/Controls/Popup";
import Notification from "../../../components/SnackBar/Notification";
import { makeStyles, TableCell, Tooltip } from "@material-ui/core";
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme from "../../../components/CustomTheme/CustomTheme";
import Confirm from "./Confirm";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  iconButtonColor: {
    color: "#fff",
    "&:hover": {
      color: "#0000ff",
    },
  },
}));

function RMInventoryMachineList() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [MachineList, setMachineList] = useState([]);


  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const AxiosHeader = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  async function fetchAvailableRentMachines() {
    setIsLoading(true);
    try {
      await axios
        .get(`/api/rent-machine-inventory-available-list/`, AxiosHeader)
        .then((res) => {
          setMachineList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    fetchAvailableRentMachines();
  }, [ ]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "serial_no",
      label: "Serial no",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "supplier",
      label: "Supplier",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "machine_type",
      label: "Machine type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "get_first_running_start_date",
      label: "Start / In date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "get_last_running_end_date",
      label: "End / Out date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "get_due_day",
      label: "Due Days (approximate)",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <span style={
                {
                  backgroundColor: value === 0 ? '#fff3cd' : value < 0 ? '#d4edda' : '#f8d7da',
                  borderRadius: '50%',
                  padding: '5px 10px',
                  display: 'inline-block',
                  textAlign: 'center',
                  fontWeight: '700',
                }
              }>
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "get_last_running_total_days",
      label: "Total running days",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "get_is_billed",
      label: "Is billed?",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CustomCircularProgress
            size={70}
            thickness={5}
            color="secondary"
            message="Data is loading. Please do not close the tab..."
          />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    filterType: "select",
    selectableRows: 'none', //multiple | single | none 
    responsive: "standard",
    rowsPerPage: [200],
    rowsPerPageOptions: [20, 50, 100, 200, 500],
    customToolbar: () => {
      return (
        <>
          
        </>
      );
    },
    print: false,
    download: false,
  };

  return (
    <React.Fragment>
      <Box position="relative">
        <ThemeProvider theme={CustomTheme}>
          <div>
            <BreadCrumb routeSegments={[{ name: "Rent Machine List" }]} />
            {isLoading ? (
              <MUIDataTable
                title={"Rent Machine List"}
                columns={columns}
                options={options}
                className={classes.pageContent}
              />
            ) : (
              <MUIDataTable
                title={"Rent Machine List"}
                data={MachineList}
                columns={columns}
                options={options}
                className={classes.pageContent}
              />
            )}
            <TableCell className={classes.MuiTableCell} />
            <Notification notify={notify} setNotify={setNotify} />
          </div>
        </ThemeProvider>
      </Box>
    </React.Fragment>
  );
}

export default RMInventoryMachineList;