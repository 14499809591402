import { Input, InputCheckBox } from './Input';
import DatePicker from './DatePicker';
import MonthPicker from './MonthPicker';
import CustomAutocomplete from './CustomAutocomplete';
import Autocomplete from './Autocomplete';
import Select from './Select';
import Button from './Button';
import ActionButton from './ActionButton';
import {CustomImage} from './CustomImage';

const Controls = {
    Input,
    InputCheckBox,
    Select,
    Button,
    ActionButton,
    DatePicker,
    MonthPicker,
    CustomAutocomplete,
    Autocomplete,
    CustomImage
}

export default Controls